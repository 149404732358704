import { apiSlice } from 'redux/rtkQuery/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/authenticate',
        method: 'POST',
        body: { ...credentials }
      }),
      transformResponse: async (response, meta) => {
        const token = meta?.response.headers.get('Authorization');
        const data = await response;
        return { data, token };
      }
    }),
    sendOtpCode: builder.mutation({
      query: (credentials) => ({
        url: '/auth/authenticate',
        method: 'POST',
        body: { ...credentials }
      })
    }),
    getUserDetails: builder.mutation({
      query: () => '/customer/'
    }),
    getAccessCode: builder.mutation({
      query: () => '/auth/accessCode'
    }),
    loginViaAccessCode: builder.mutation({
      query: (code) => `/auth/authenticate?accessCode=${code}`,
      transformResponse: async (response, meta) => {
        const token = meta?.response.headers.get('Authorization');
        const data = await response;
        return { data, token };
      }
    })
  })
});

export const {
  useLoginMutation,
  useSendOtpCodeMutation,
  useGetAccessCodeMutation,
  useGetUserDetailsMutation,
  useLoginViaAccessCodeMutation
} = authApiSlice;
