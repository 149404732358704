import PropTypes from 'prop-types';
import { findUserType } from 'utils/functions';
import { ellipsis } from 'utils/functions/text';

const MemberList = ({ users }) => {
  return (
    <div className="filson-pro-regular font16px text-center">
      {users.map(({ memberNumber, firstName, lastName, relationshipType }, index) => (
        <p key={index}>{`${ellipsis(firstName)} ${ellipsis(
          lastName
        )} #${memberNumber} (${findUserType(relationshipType)})`}</p>
      ))}
    </div>
  );
};

MemberList.propTypes = {
  users: PropTypes.array
};

export default MemberList;
