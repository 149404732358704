import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = () => {
  return (
    <LinearProgress
      sx={{
        backgroundColor: '#c9eaff',
        zIndex: 9999,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#0070BA'
        }
      }}
    />
  );
};

export default ProgressBar;
