import { ModelBody, ModelHeader, ModelTitle } from '../style';
import { useSelector } from 'react-redux';

const Warning = () => {
  const { data } = useSelector((state) => state.model);
  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle></ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center font13px filson-pro-regular mt-2 mb-5">
          <b>{`This membership is currently on a ${data.name}`}</b>
          <p className="mt-3">
            Only the Primary Account Holder is authorized to make payments using the card on file
            for this membership.
            <br /> Please ask the Primary Account holder to login to their account to make payment
            to renew this membership.
          </p>
        </div>
      </ModelBody>
    </>
  );
};

export default Warning;
