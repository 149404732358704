import { createSlice } from '@reduxjs/toolkit';
import { isNullOrEmpty } from 'utils/functions/validator';
import { getPrimaryMember } from '../utils/functions';

const initialState = {
  membershipPlanName: '',
  paymentPlan: '',
  amountWithoutDiscount: 0,
  discount: 0,
  price: 0,
  couponCode: '',
  cardType: '',
  crn: '',
  validTillDate: '',
  isCouponApplied: false,
  isConcessionApplied: false
};

export const payNow = createSlice({
  name: 'payNow',
  initialState,
  reducers: {
    updateEstimate: (state, action) => {
      let membership = action.payload;
      let { config, couponEstimate } = membership;
      let { coupon } = getPrimaryMember(membership);
      let { validTillDate } = config;
      const externalCouponIsPresent = 'couponCode' in couponEstimate;
      const isConcessionApplied = !isNullOrEmpty(coupon?.cardType);

      state.membershipPlanName = couponEstimate.name;
      state.paymentPlan = couponEstimate;
      state.amountWithoutDiscount = couponEstimate.amountWithoutDiscount;
      state.discount = couponEstimate?.discount;
      state.price = couponEstimate?.price;
      state.validTillDate = validTillDate;
      state.isConcessionApplied = isConcessionApplied;

      if (isConcessionApplied) {
        let { crn, cardType } = coupon;
        state.crn = crn;
        state.cardType = cardType;
      } else {
        // reset
        state.crn = '';
        state.cardType = '';
      }

      if (externalCouponIsPresent) {
        state.couponCode = couponEstimate.couponCode;
        state.isCouponApplied = true;
      } else {
        // reset
        state.couponCode = '';
        state.isCouponApplied = false;
      }
    },
    payNowReset: () => {
      return initialState;
    }
  }
});

export const { updateEstimate, payNowReset } = payNow.actions;

export default payNow.reducer;
