import { ModelHeader, ModelTitle, ModelBody } from '../style';
import { useSelector } from 'react-redux';

export default function RenewalLogin() {
  const { data } = useSelector((state) => state.model);

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>{data.title ?? 'Login Failed'}</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mb-5">
          <p className="font12px mt-5">{data.message}</p>
        </div>
      </ModelBody>
    </>
  );
}
