export function transformKeys(obj, func) {
  const newObject = {};
  Object.keys(obj).forEach((key, index, array, value = obj[key]) => {
    newObject[func(key)] = value;
  });
  return newObject;
}

export function deepTransformKeys(obj, func) {
  if (!obj) return obj;
  if (typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map((element) => deepTransformKeys(element, func));
  }
  const newObject = {};
  Object.keys(obj).forEach((key, index, array, value = obj[key]) => {
    newObject[func(key)] = deepTransformKeys(value, func);
  });
  return newObject;
}

export function deepClone(obj, hash = new WeakMap()) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (hash.has(obj)) {
    return hash.get(obj);
  }

  let clonedObj;

  if (Array.isArray(obj)) {
    clonedObj = [];
    hash.set(obj, clonedObj);
    for (let i = 0; i < obj.length; i++) {
      clonedObj[i] = deepClone(obj[i], hash);
    }
  } else {
    clonedObj = {};
    hash.set(obj, clonedObj);
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clonedObj[key] = deepClone(obj[key], hash);
      }
    }
  }

  return clonedObj;
}

export const freeze = (obj) => Object.freeze(obj);
