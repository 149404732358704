import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const ModelHeader = styled(Modal.Header).attrs({
  className: 'text-center'
})`
  border-top: 3px solid #00a893;
`;

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
  text-align: center;
`;

export const ModelBody = styled(Modal.Body).attrs({
  className: 'font13px filson-pro-regular'
})`
  height: 100%;
`;

export const ModelBtn = styled.button`
  background-color: #ffffff;
  color: #00a893;
  font-weight: bolder;
  border: 2px solid #00a893;
  border-radius: 5px;
  padding: 1vh 1vw 1vh 1vw;
  margin-top: 4vh;
  font-size: 12px;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

export const ModelContent = styled.span``;

export const CheckboxContent = styled.span.attrs({
  className: 'filson-pro-regular font13px'
})`
  color: #5b5b5b;
`;

export const BoldFont = styled.p.attrs({
  className: 'filson-pro-bold font13px'
})`
  color: #000000;
  font-weight: bold;
`;

export const RegularFont = styled.p.attrs({
  className: 'filson-pro-regular font13px'
})`
  color: #ff0000;
`;
