import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar';
import { Model } from 'components/Model';
import SnackbarAlert from 'components/Snackbar';
import Overlay from 'components/Overlay';
import NotFound from 'components/PageNotFound';

import { PublicRoutes } from './publicRoutes';
import { StoreRoutes } from './storeRoutes';
import { PortalRoutes } from './portalRoutes';
import { InstantRenewalRoutes } from './instantRenewalRoutes';

const App = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<ProgressBar />}>
          <Routes>
            {PublicRoutes()}
            {PortalRoutes()}
            {StoreRoutes()}
            {InstantRenewalRoutes()}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Model />
      </Router>
      <SnackbarAlert />
      <Overlay />
    </>
  );
};

export default App;
