import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoutes } from 'routes/wrapper/privateRoute';
import lazyWithFallback from 'utils/lazyWithFallback';

const InstantRenewal = lazyWithFallback(() => import('pages/instantRenewal/renewal'));
const InstantRenewalPayment = lazyWithFallback(() => import('pages/instantRenewal/payment'));
const InstantRenewalThankYou = lazyWithFallback(() => import('pages/instantRenewal/thankYou'));

export const InstantRenewalRoutes = () => (
  <Route element={<PrivateRoutes />}>
    {/*instant renewal route*/}
    <Route exact path="/portal/renewal" element={<InstantRenewal />} />
    <Route exact path="/portal/payment" element={<InstantRenewalPayment />} />
    <Route exact path="/portal/thankYou" element={<InstantRenewalThankYou />} />
  </Route>
);
