import { createSlice } from '@reduxjs/toolkit';
import api from 'utils/functions/api';
import { NP_ENGINE } from 'settings/dev';

const initialState = {
  index: 0,
  selectedUserId: 0,
  customerId: '',
  subscriptionId: '',
  membershipPlan: '',
  tempMembershipPlan: '',
  paymentPlan: '',
  itemList: [],
  isConcessionApplied: false,
  totalCost: '',
  validUntil: '',
  concession: null,
  cardType: null,
  crn: '',
  couponCode: '',
  isCouponApplied: false,
  isEstimated: false,
  membership: {},
  familyList: [],
  affiliate: false,
  isPrimaryUser: true
};

export const renew = createSlice({
  name: 'renew',
  initialState,
  reducers: {
    initRenew: (state, action) => {
      let {
        index,
        selectedUserId,
        customerId,
        subscriptionId,
        membershipPlan,
        paymentPlan,
        isConcessionApplied,
        validTillDate,
        itemList,
        totalCost,
        concession,
        cardType,
        crn,
        couponEstimate,
        membership,
        affiliate,
        familyList,
        isPrimaryUser
      } = action.payload;

      state.index = index;
      state.selectedUserId = selectedUserId;
      state.customerId = customerId;
      state.subscriptionId = subscriptionId;
      state.membershipPlan = membershipPlan;
      state.paymentPlan = paymentPlan;
      state.tempMembershipPlan = membershipPlan;
      state.itemList = itemList;
      state.isConcessionApplied = !!isConcessionApplied;
      state.totalCost = totalCost;
      state.validTillDate = validTillDate;
      state.concession = concession;
      state.cardType = cardType;
      state.crn = crn;
      state.couponCode = couponEstimate?.couponCode ?? '';
      state.isCouponApplied = !!couponEstimate?.couponCode;
      state.membership = membership;
      state.affiliate = affiliate;
      state.familyList = familyList;
      state.isPrimaryUser = isPrimaryUser;
    },
    updateRenew: (state, action) => {
      let {
        membershipPlan,
        paymentPlan,
        isConcessionApplied,
        itemList,
        totalCost,
        concession,
        cardType,
        crn,
        familyList
      } = action.payload;

      state.membershipPlan = membershipPlan;
      state.paymentPlan = paymentPlan;
      state.itemList = itemList;
      state.isConcessionApplied = isConcessionApplied;
      state.totalCost = totalCost;
      state.concession = concession;
      state.cardType = cardType;
      state.crn = crn;
      state.familyList = familyList;
    },
    renewalResetCoupon: (state) => {
      state.couponCode = '';
      state.isCouponApplied = false;
    },
    renewReset: () => {
      return initialState;
    }
  }
});

export const { initRenew, updateRenew, renewReset, renewalResetCoupon } = renew.actions;

export const processRenew = (payload) => () =>
  api.post(NP_ENGINE, `/store/changeSubscription`, payload).then((response) => {
    return response;
  });

export default renew.reducer;
