import { apiSlice } from 'redux/rtkQuery/apiSlice';

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    paymentAction: builder.mutation({
      query: (payload) => ({
        url: '/customer/paymentMethods',
        method: 'POST',
        body: { ...payload }
      })
    })
  })
});

export const { usePaymentActionMutation } = paymentApiSlice;
