import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

import { ButtonLoader } from 'components/Loader';
import EmptyBlock from 'components/EmptyBlock';

import { cancelMembership, temporaryPlanUpdate, updatePlanConfig } from 'redux/membership';
import { closeModel } from 'redux/model';
import { showSnackbar } from 'redux/snackbar';

import { ellipsis, toTitleCase } from 'utils/functions/text';
import { MEMBERSHIP_PLAN, PAYMENT_PLAN, RELATIONSHIP, USER_TYPE } from 'utils/constants/variable';
import {
  checkIsExpiredMembership,
  convertToDropdown,
  convertToUpgradeDropdown,
  membershipTotalCost,
  toSmallerCase
} from 'utils/functions';

import { active, Btn, disable, dropDown, ModelBtn } from 'styles';
import { Heading, Title, MemberNumber, UserName, UserWrap, CancelTxt, Label } from './style';
import { ModelTitle } from '../style';

const Role = {
  PR: RELATIONSHIP.PRIMARY,
  SP: RELATIONSHIP.SPOUSE_PARTNER,
  CD: RELATIONSHIP.CHILD_DEPENDANT
};

export default function SwitchPlan() {
  const { plans_config, dropdown } = useSelector((state) => state.chargebee);
  const { memberships } = useSelector((state) => state.store);
  const { plans, planDropdown, membershipPlan, tempMembershipPlan, tempPaymentPlan } = useSelector(
    (state) => state.membership
  );
  const { data } = useSelector((state) => state.model);
  // const { activeUser } = data;

  const { users, item, config } = memberships[data.index];

  const { isEligibleForRenewal, isScheduledChanges, nextPaymentDueDate, validTillDate, status } =
    config;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    membershipPlan: tempMembershipPlan,
    paymentPlan: '',
    planList: [],
    itemList: []
  });
  const [error, setError] = useState('');
  const [cancel, setCancel] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const selectPaymentPlan = (itemList, { id }) => itemList.find((item) => item.value === id);

  const getUpgradeFreqList = (selectedPlan = tempMembershipPlan) => {
    let isCancelledMembership = checkIsExpiredMembership(config.status);
    return convertToUpgradeDropdown(
      plans,
      selectedPlan,
      isCancelledMembership,
      tempMembershipPlan,
      item,
      false,
      ''
    );
  };

  useEffect(() => {
    const membershipPlanName = toSmallerCase(membershipPlan);
    let { upgrade } = plans_config[membershipPlanName];
    let upgradablePlanList = planDropdown.filter((plan) => upgrade.includes(toSmallerCase(plan)));
    let itemList = convertToDropdown(tempMembershipPlan.value, plans);
    let selectedPaymentPlan = selectPaymentPlan(itemList, tempPaymentPlan);
    setInfo({
      ...info,
      planList: upgradablePlanList,
      itemList: getItemList(tempMembershipPlan ? tempMembershipPlan : membershipPlan),
      paymentPlan: selectedPaymentPlan
    });
  }, []);

  // useEffect(() => {
  //   const membershipPlanName = toSmallerCase(membershipPlan);
  //   let { upgrade } = plans_config[membershipPlanName];
  //   let upgradablePlanList = planDropdown.filter((plan) => upgrade.includes(toSmallerCase(plan)));
  //   let itemList = getItemList(membershipPlan);
  //   let selectedPaymentPlan = selectPaymentPlan(itemList, tempPaymentPlan);

  //   setInfo({
  //     ...info,
  //     planList: upgradablePlanList,
  //     itemList,
  //     paymentPlan: selectedPaymentPlan
  //   });
  // }, []);

  const getPaymentPlan = (paymentPlan) => {
    return `${toTitleCase(paymentPlan)} over 12 months subscription`;
  };

  const getPrimaryMember = (users) =>
    users.find((user) => user.relationshipType === USER_TYPE.PRIMARY);

  const handleDropdownChange = (key, selectedPlan) => {
    let membershipDetails = {};

    switch (key) {
      case MEMBERSHIP_PLAN:
        membershipDetails = {
          itemList: getItemList(selectedPlan),
          paymentPlan: '',
          totalCost: ''
        };
        break;

      case PAYMENT_PLAN:
        membershipDetails.totalCost = membershipTotalCost(selectedPlan);
        setError('');
        break;
    }
    setInfo({ ...info, ...membershipDetails, [key]: selectedPlan });
  };

  const isCancelledMembership = checkIsExpiredMembership(status);

  const getItemList = (selectedPlan) => {
    return isEligibleForRenewal
      ? convertToDropdown(membershipPlan.value, plans)
      : getUpgradeFreqList(selectedPlan);
  };

  const handleClose = () => dispatch(closeModel());

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const handleCancel = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      let primaryUser = getPrimaryMember(users);
      let payload = {
        user: {
          memberNumber: primaryUser.memberNumber
        },
        reasonCode: reasonCode.value
      };
      setIsProcessing(true);
      dispatch(cancelMembership(payload))
        .then(() => {
          setIsProcessing(true);
          dispatch(closeModel());
        })
        .then(() => navigate('/store/hub/confirmation'))
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          dispatch(showSnackbar({ message: err.message, severity: 'error' }));
        });
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (info.paymentPlan.length === 0) {
      setError('Please select a valid value');
    } else {
      let { membershipPlan, paymentPlan } = info;
      const selectedPlanConfig = plans_config[toSmallerCase(membershipPlan)];

      dispatch(
        temporaryPlanUpdate({
          membershipPlan,
          paymentPlan
        })
      );
      dispatch(updatePlanConfig(selectedPlanConfig));
      dispatch(closeModel());
    }
  };

  if (cancel) {
    return (
      <>
        <Modal.Header closeButton>
          <ModelTitle>Cancel Membership</ModelTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <p className="text-center filson-pro-regular font13px">
              Are you sure you would like to cancel this Membership ?
            </p>
            <p className="text-center filson-pro-regular font13px red">
              if you would like to continue your membership, please close this window <br /> or
              select the the the cancel option below.
            </p>
            <br />
            <p className="text-center filson-pro-regular font13px">
              Please note: Any unused portion of your membership will not be <br /> refunded and all
              benefits are cancelled immediately.
            </p>

            <div className="container font13px" style={{ width: '30%' }}>
              <div className="text-center">
                <Label htmlFor="reasonCode">Please select a reason:</Label>
              </div>
              <Select
                styles={dropDown('')}
                options={dropdown.reasonCodeForCancel}
                value={reasonCode}
                isSearchable={false}
                onChange={handleReasonCodeChange}
              />
              <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
            </div>

            <div className="text-center mb-3">
              <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClose}>
                Cancel
              </ModelBtn>
              <ModelBtn
                onClick={handleCancel}
                style={isProcessing ? disable : active}
                isProcessing={isProcessing}
                disabled={isProcessing}>
                {isProcessing ? <ButtonLoader /> : 'Confirm'}
              </ModelBtn>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  } else {
    return (
      <>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Title>Membership Details</Title>
            <Heading>
              <b>{item.name}</b>
              {/* {isAffiliate ? (
                <b>{`${membershipType} - Affiliate - ${membershipPlan.value}`}</b>
              ) : (
                <b>{`National Pharmacies - ${toTitleCase(membershipPlan.value)} ${
                  concession ? (isHealthOpt() ? '- Concession ' : '- Seniors') : ''
                } ${activeUser.pre64 ? ' - Pre64' : ''}`}</b>
              )} */}
            </Heading>

            <div className="text-center filson-pro-bold mb-3">
              <b className="d-block">{getPaymentPlan(item.name)}</b>

              {isScheduledChanges && (
                <>
                  <Heading className="mt-3">
                    <b>{`Membership renewed to ${
                      item.concession ? 'Concession -' : ''
                    } ${toTitleCase(item.planName)}`}</b>
                  </Heading>
                  <b className="d-block">{getPaymentPlan(item.name)}</b>
                </>
              )}

              <b className="d-block">{`Membership Benefit until ${validTillDate}`}</b>

              {nextPaymentDueDate && (
                <b className="d-block">{`Next payment due: ${nextPaymentDueDate}`}</b>
              )}
            </div>

            {users.map(({ firstName, lastName, memberNumber, relationshipType }, index) => (
              <UserWrap key={index}>
                <UserName className="text-align-center">
                  {`${ellipsis(`${firstName} ${lastName}`)}`}
                </UserName>

                <MemberNumber className="text-align-center">
                  {`Member #${memberNumber} (${Role[relationshipType]})`}
                </MemberNumber>
              </UserWrap>
            ))}
            <br />
            <br />
            <div className="container filson-pro-regular">
              <div className="row">
                <div className="col-md-6  pt-1 text-center">Membership Plan</div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4 pt-1">Switch to:</div>
                    <div className="col-md-8 font13px">
                      <Select
                        styles={dropDown('')}
                        options={info.planList}
                        value={info.membershipPlan}
                        isSearchable={false}
                        onChange={(e) => handleDropdownChange(MEMBERSHIP_PLAN, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="container filson-pro-regular">
              <div className="row">
                <div className="col-md-6 pt-1 text-center">Payment Plan</div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4  pt-1">Switch to:</div>
                    <div className="col-md-8">
                      <Select
                        styles={dropDown('')}
                        options={info.itemList}
                        isSearchable={false}
                        value={info.paymentPlan}
                        onChange={(e) => handleDropdownChange(PAYMENT_PLAN, e)}
                        placeholder={''}
                      />
                      <span className="error">{error}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isCancelledMembership ? (
              <EmptyBlock />
            ) : (
              <CancelTxt onClick={() => setCancel(true)}>Cancel Membership</CancelTxt>
            )}

            <br />

            <p className="text-center filson-pro-regular font13px">
              If you have any questions relating to your Membership, please contact Member Services
              on 1300 667 676.
            </p>

            <div className="text-center mb-5">
              <Btn onClick={handleSubmit}>Save</Btn>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }
}
