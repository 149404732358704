import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { authReset, logout } from 'redux/auth';
import { membershipReset } from 'redux/membership';
import { detailsReset } from 'redux/details';
import { estimateReset } from 'redux/estimate';
import { paymentReset } from 'redux/payment';
import { portalReset } from 'redux/portal';
import { closeModel } from 'redux/model';

import { ModelBody, ModelHeader } from '../style';

export const ModelBtn = styled.button`
  background-color: #ffffff;
  color: #00a893;
  font-weight: bolder;
  border: 2px solid #00a893;
  border-radius: 5px;
  padding: 1vh 2vw 1vh 2vw;
  margin-top: 4vh;
  font-size: 12px;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

const Logout = () => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logout())
      .then(() => {
        dispatch(membershipReset());
        dispatch(detailsReset());
        dispatch(estimateReset());
        dispatch(paymentReset());
        dispatch(portalReset());
        dispatch(authReset());
      })
      .then(() => hideModel());
  };

  const hideModel = () => dispatch(closeModel());

  return (
    <>
      <ModelHeader closeButton></ModelHeader>
      <ModelBody>
        <div className="text-center mt-2 mb-5">
          <p>Are you sure you want to Logout ?</p>
          <div>
            <ModelBtn onClick={hideModel} style={{ marginRight: '10%' }}>
              Cancel
            </ModelBtn>
            <ModelBtn onClick={signOut}>Logout</ModelBtn>
          </div>
        </div>
      </ModelBody>
    </>
  );
};

export default Logout;
