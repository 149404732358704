import policies from 'policies_v2.json';

export const findAccessCode = (affiliate, affiliateProduct) => {
  const ACCESS_CODE = Object.freeze({
    GREEN: 'green',
    BLUE: 'blue',
    ORANGE: 'orange',
    WHITE: 'white'
  });

  const accessRules = {
    np_direct: {
      'NP Direct': ACCESS_CODE.GREEN,
      'NP Director': ACCESS_CODE.GREEN,
      'NP Life Member': ACCESS_CODE.GREEN,
      'NP Employee': ACCESS_CODE.BLUE,
      'NP Health Practitioner': ACCESS_CODE.WHITE
    },
    ufs: {
      'NP Direct': ACCESS_CODE.GREEN
    },
    bupa: {
      'Bupa Single': ACCESS_CODE.ORANGE,
      'Bupa Couple': ACCESS_CODE.ORANGE,
      'Bupa Family': ACCESS_CODE.ORANGE,
      'Bupa Family Plus': ACCESS_CODE.ORANGE,
      'Bupa Sole Parent': ACCESS_CODE.ORANGE,
      'Bupa Sole Parent Plus': ACCESS_CODE.ORANGE
    },
    police_health: {
      'Police Health Single': ACCESS_CODE.ORANGE,
      'Police Health Family': ACCESS_CODE.ORANGE,
      'Police Health Family Plus': ACCESS_CODE.ORANGE
    },
    esh: {
      'Emergency Services Single': ACCESS_CODE.ORANGE,
      'Emergency Services Family': ACCESS_CODE.ORANGE
    }
  };
  return accessRules[affiliate]?.[affiliateProduct] || ACCESS_CODE.WHITE;
};

export const findUserPolicy = (
  { config: { status, subscriptionType, affiliateProduct } },
  userType
) => {
  const getSubscriptionStatus = (key) => {
    let subscriptionStatus = {
      ACTIVE: 'active',
      EXPIRED: 'expired',
      'PAYMENT DUE': 'payment_due'
    };
    return subscriptionStatus[key];
  };
  const subscriptionStatus = getSubscriptionStatus(status);
  const accessCode = findAccessCode(subscriptionType, affiliateProduct);
  return policies[accessCode][userType][subscriptionStatus];
};
