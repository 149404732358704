import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonLoader } from 'components/Loader';

import { closeModel } from 'redux/model';
import { getEstimate, initUserDetails } from 'redux/estimate';
import { updatePortal } from 'redux/portal';

import { active, Btn, disable } from 'styles';
import { ModelBody, ModelHeader, ModelTitle } from '../style';

const Upgrade = () => {
  const { data } = useSelector((state) => state.model);
  const { isEstimated, estimatePayload, userInfo, portalInfo } = data;
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (isEstimated) {
      postAction();
      navigate('/mymembership/payment');
      dispatch(closeModel());
    } else {
      setIsProcessing(true);
      dispatch(getEstimate(estimatePayload))
        .then(() => {
          postAction();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsProcessing(false);
          dispatch(closeModel());
        });
    }
  };

  const postAction = () => {
    dispatch(initUserDetails(userInfo));
    dispatch(updatePortal(portalInfo));
    navigate('/mymembership/payment');
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Membership Upgrade</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mt-2 mb-5">
          <div>
            <p>
              You have selected to make changes to your Membership Plan and/or Payment plan.
              <br />
              You will be redirected to the Payment Page to complete this process.
            </p>
          </div>
        </div>
        <div className="text-center mb-5">
          <Btn
            onClick={handleSubmit}
            style={isProcessing ? disable : active}
            isProcessing={isProcessing}
            disabled={isProcessing}>
            {isProcessing ? <ButtonLoader /> : 'Ok'}
          </Btn>
        </div>
      </ModelBody>
    </>
  );
};

export default Upgrade;
