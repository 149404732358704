import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { Btn } from 'styles';
import { useDispatch } from 'react-redux';
import { closeModel } from 'redux/model';

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
  text-align: center;
`;

export const Label = styled.span.attrs({
  className: 'text-center filson-pro-regular'
})`
  font-size: 13px;
`;

const BadAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(closeModel());
    navigate('/mymembership/communication/edit');
  };

  return (
    <>
      <Modal.Header closeButton>
        <ModelTitle>Invalid Address</ModelTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <p className="text-center filson-pro-bold font15px red">
            Your address appears to be incorrect!
          </p>
          <div className="text-center filson-pro-regular font13px">
            Please update your details via Communication preferences
          </div>
          <br />

          <div className="text-center mb-5">
            <Btn onClick={handleClick}>Update Address</Btn>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default BadAddress;
