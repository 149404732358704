import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container'
})``;

export const ContainerFluid = styled.div.attrs({
  className: 'container-fluid'
})``;

export const Row = styled.div.attrs({
  className: 'row'
})``;

export const Col = styled.div.attrs((props) => ({
  className: `col-md-${props.md}`
}))``;
