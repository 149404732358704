import {
  PERIOD,
  FREQUENCY,
  USER_TYPE,
  CONCESSION,
  PORTAL_MEMBERSHIP_STATUS,
  SPOUSE
} from 'utils/constants/variable';
import { TITLES } from 'utils/constants/dropdown';
// import policies from 'policies.json';
import jwt_decode from 'jwt-decode';
import { store } from 'store';
import { isNullOrEmpty, getConcessionAggregatePriority, checkConcessionPresent } from './validator';

export const formatPhoneNo = (phoneNo) => {
  phoneNo = phoneNo.replaceAll(/\s/g, '');

  if (phoneNo.length === 4) {
    return addSpace(phoneNo, [3]);
  } else {
    return addSpace(phoneNo, [3, 6]);
  }
};

const addSpace = (val, sp) => {
  //sp- space position
  val = val.split('');
  for (let i = sp.length - 1; i >= 0; i--) {
    val.splice(sp[i], 0, ' ');
  }
  return val.join('').trim();
};

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

export const scrollToBottom = () => {
  setTimeout(
    () => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' }),
    100
  );
};

export const convertToDropdown = (
  selectedPlan,
  plans,
  isConcessionApplied = false,
  cardType = ''
) => {
  if (selectedPlan === 'Extra Family Member') {
    selectedPlan = 'Single';
  }
  const { itemPrices } = plans[selectedPlan];
  return itemPrices.map((item) => {
    const price = convertToDecimal(parseFloat(item.price));
    let result = {
      ...item,
      label: (
        <>
          <span className="font12px">{`Pay ${item.name} $${price}/${convertToMonths(item)} `}</span>
        </>
      ),
      value: item.id,
      amountTxt: `Pay ${item.name} $${price}/${convertToMonths(item)} `
    };

    if (isConcessionApplied) {
      const selectedConcession = item.coupons.find(
        (c) => c.type === getConcessionAggregatePriority(cardType)
      ).percentage;
      const amount = concessionAmount(price, selectedConcession);
      result = {
        ...item,
        label: (
          <>
            <span className="font12px">{`Pay ${item.name} $${amount}/${convertToMonths(
              item
            )} `}</span>
          </>
        ),
        value: item.id,
        amountTxt: `Pay ${item.name} $${amount}/${convertToMonths(item)} `
      };
    }
    return result;
  });
};

export const convertToUpgradeDropdown = (
  plans,
  selectedPlan,
  isMembershipCancelled,
  membershipPlan,
  item,
  isConcessionApplied = false,
  cardType
) => {
  const itemList = convertToDropdown(selectedPlan.value, plans, isConcessionApplied, cardType);
  if (isMembershipCancelled) {
    return itemList;
  } else {
    const frequencies = new Set();
    const yearlyFreq = itemList.find(({ periodUnit }) => periodUnit === PERIOD.YEAR);
    frequencies.add(yearlyFreq);

    if (selectedPlan.value !== membershipPlan.value) {
      const freqMatch = itemList.find(({ period, periodUnit }) => {
        if (period === item.period && periodUnit === item.periodUnit) {
          return item;
        }
      });
      frequencies.add(freqMatch);
    }

    return Array.from(frequencies);
  }
};

export const membershipTotalCost = (paymentPlan, isConcessionApplied = false, cardType = null) => {
  console.log('paymentPlan', paymentPlan);
  if (Object.keys(paymentPlan).length === 0) return ''; // skip if payment plan is empty
  const { periodUnit, period, price, coupons } = paymentPlan;
  const healthConcession = coupons.find((c) => c.type === CONCESSION.HEALTH).percentage;
  const seniorConcession = coupons.find((c) => c.type === CONCESSION.SENIOR).percentage;
  if (periodUnit === PERIOD.MONTH) {
    // No of billing cycle required to complete one year.
    let billingCycle = period === FREQUENCY.MONTHLY ? 12 : period === FREQUENCY.QUARTERLY ? 4 : 2;

    let healthConcessionDiscount = concessionAmount(price, healthConcession, billingCycle);
    let seniorConcessionDiscount = concessionAmount(price, seniorConcession, billingCycle);
    let billingCycleTxt =
      billingCycle === 12 ? 'monthly' : billingCycle === 2 ? 'half yearly' : 'quarterly';

    let membershipTxt = `Total cost is $${convertToDecimal(price * billingCycle)}`;
    let concessionTxt = `($${healthConcessionDiscount} Concession or $${seniorConcessionDiscount} Senior Card) over 12 months with ${billingCycleTxt} payment plan. `;

    if (isConcessionApplied) {
      let message = {
        [CONCESSION.HEALTH]: `Total cost is $${healthConcessionDiscount} with Concession Card Discount over 12 months with ${billingCycleTxt} payment plan. `,
        [CONCESSION.SENIOR]: `Total cost is $${seniorConcessionDiscount} with Senior Card Discount over 12 months with ${billingCycleTxt} payment plan. `
      };

      return message[getConcessionAggregatePriority(cardType)];
    }

    return `${membershipTxt} ${concessionTxt}`;
  } else if (periodUnit === PERIOD.YEAR) {
    let healthConcessionDiscount = concessionAmount(price, healthConcession, period);
    let seniorConcessionDiscount = concessionAmount(price, seniorConcession, period);
    let membershipTxt = `Total cost is $${convertToDecimal(price)} `;
    let concessionTxt = `($${healthConcessionDiscount} Concession or $${seniorConcessionDiscount} Senior Card) over 12 months with yearly payment plan. `;

    if (isConcessionApplied) {
      let message = {
        [CONCESSION.HEALTH]: `Total cost is $${healthConcessionDiscount} with Concession Card Discount over 12 months with yearly payment plan. `,
        [CONCESSION.SENIOR]: `Total cost is $${seniorConcessionDiscount} with Senior Card Discount over 12 months with yearly payment plan. `
      };
      return message[getConcessionAggregatePriority(cardType)];
    }

    return `${membershipTxt} ${concessionTxt}`;
  }

  return '';
};

export const concessionAmount = (price, discountPercent, billingCycle = 1) => {
  let discountAmount = price * (discountPercent / 100);
  let roundOff = Math.round((discountAmount + Number.EPSILON) * 100) / 100;
  let amount = price - roundOff;
  return convertToDecimal(amount * billingCycle);
};

const convertToMonths = ({ period, periodUnit }) => {
  if (periodUnit === PERIOD.MONTH) {
    return `${period} months`;
  } else {
    return `${period * 12} months`;
  }
};

const convertToDecimal = (val) => val.toFixed(2);

// export const getMembershipPlan = ({ planName }, planList) =>
//   planList.find((plan) => plan.value === planName);

export const getMembershipPlan = ({ planName }, planList) => {
  return planList.find((plan) => plan.value === planName) ?? planList[0];
};

export const getPaymentPlan = (itemList, { id }) => itemList.find((item) => item.value === id);

export const getActiveUser = (users, loggedInUserMemberNumber) =>
  users.find((user) => user.memberNumber === loggedInUserMemberNumber);

export const getNonActiveUserWithPrimary = (users, loggedInUserMemberNumber) =>
  users.filter((user) => user.memberNumber !== loggedInUserMemberNumber);

export const getNonActiveUser = (users, loggedInUserMemberNumber) =>
  users.filter(
    (user) =>
      user.relationshipType !== USER_TYPE.PRIMARY && user.memberNumber !== loggedInUserMemberNumber
  );

export const convertToHtml = (item, discount) => {
  return {
    amountTxt: `Pay ${item.name} $${convertToDecimal(item.price)}/${convertToMonths(item)} `,
    concessionTxt: `$${concessionAmount(item.price, discount)} Seniors/Concession Card`
  };
};

export const getTitle = (t) => TITLES.find((title) => title.value === t);

export const checkPeriodUnitIsMonth = ({ periodUnit }) => periodUnit === PERIOD.MONTH;

export const convertToPlanDropdown = (plans) => {
  let planDropdown = [];
  for (const key in plans) {
    planDropdown.push({ value: key, label: key, level: plans[key].level });
  }
  return planDropdown;
};

export const getPrimarySubscriptionId = (users) =>
  users.find((user) => user.relationshipType === USER_TYPE.PRIMARY).subscriptionId;

export const getPrimaryMember = ({ users }) =>
  users.find((user) => user.relationshipType === USER_TYPE.PRIMARY);

export const getSpouseMember = ({ users }) =>
  users.find((user) => user.relationshipType === USER_TYPE.SPOUSE_PARTNER);

export const getDependentMember = ({ users }, loggedInUserId) =>
  users.filter((user) => {
    if (user.memberNumber === loggedInUserId || user.relationshipType === USER_TYPE.PRIMARY) {
      return;
    }
    return user;
  });

export const toSmallerCase = ({ value }) => value.toLowerCase();

export const preferredPaymentSort = (paymentSources) =>
  paymentSources.sort((a, b) => b.primaryPaymentSource - a.primaryPaymentSource);

export const findLoggedInUser = (users, id) => users.find((user) => user.memberNumber === id);

export const isEqual = (arg1, arg2) => arg1 === arg2;

export const isEmpty = (arr) => arr.length === 0;

export const getUserTypeFromToken = (data, token) => {
  const { memberno } = jwt_decode(token);
  return getUserType(data.users, memberno);
};
export const getUserType = (users, id) => {
  const loggedInUserType = findLoggedInUser(users, id).relationshipType;
  return findUserType(loggedInUserType);
};

export const findUserType = (userType) => {
  switch (userType) {
    case USER_TYPE.PRIMARY:
      return 'primary';
    case USER_TYPE.SPOUSE_PARTNER:
      return 'spouse';
    case USER_TYPE.CHILD_DEPENDANT:
      return 'child';
    default:
      return null;
  }
};

export const getPlanConfig = (plansConfig, membershipPlan, relationshipType) => {
  let selectedPlanConfig = JSON.parse(JSON.stringify(plansConfig[toSmallerCase(membershipPlan)]));

  // Modify plan config if non-primary user login
  if (relationshipType === USER_TYPE.SPOUSE_PARTNER) {
    selectedPlanConfig.maxDependent = selectedPlanConfig.maxDependent - 1;
    if (toSmallerCase(membershipPlan) === 'couple') {
      selectedPlanConfig.familyDetails = false;
      selectedPlanConfig.relationshipType = [];
    }
  } else if (relationshipType === USER_TYPE.CHILD_DEPENDANT) {
    selectedPlanConfig.familyDetails = false;
    selectedPlanConfig.maxDependent = 0;
    selectedPlanConfig.relationshipType = [];
  }

  return selectedPlanConfig;
};

export const checkIsActiveMembership = (status) => status === PORTAL_MEMBERSHIP_STATUS.ACTIVE;

export const checkIsExpiredMembership = (status) => status === PORTAL_MEMBERSHIP_STATUS.EXPIRED;

export const checkIsPaymentDueMembership = (status) =>
  status === PORTAL_MEMBERSHIP_STATUS.PAYMENT_DUE;

// const getSubscriptionStatus = (key) => {
//   let subscriptionStatus = {
//     ACTIVE: 'active',
//     EXPIRED: 'expired',
//     'PAYMENT DUE': 'payment_due'
//   };
//   return subscriptionStatus[key];
// };

// export const findUserPolicy = ({ config: { status, subscriptionType } }, userType) => {
//   const subscriptionStatus = getSubscriptionStatus(status);
//   return subscriptionType in policies
//     ? policies[subscriptionType][userType][subscriptionStatus]
//     : [];
// };

export const validateSupportedAction = (policy, action) => policy.includes(action);

export function capitalizeFirstLetter(input) {
  if (typeof input === 'string' && input.length > 0) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  return input;
}

export const formatConcessionCoupon = ({ concession, coupon }) => (concession ? { coupon } : {});

export const getSelectedOption = (optionList, value) => {
  return optionList ? optionList.find((option) => option.value === value) || null : null;
};

export const getNonPrimaryConcession = (memberList) =>
  memberList.reduce((array, member) => {
    let { coupon } = member;
    return checkConcessionPresent(member) ? [...array, coupon.cardType] : array;
  }, []);

export const findPreferredConcession = (primaryConcession, familyMembers) => {
  const { chargebee } = store.getState();
  const { concessionPriority } = chargebee;

  // coupon should not be null / empty
  let couponArray = [primaryConcession, ...getNonPrimaryConcession(familyMembers)].filter(
    (coupon) => coupon !== null && coupon !== ''
  );

  if (!couponArray.length) {
    return '';
  }

  let rank = Number.MAX_SAFE_INTEGER;
  let preferredCoupon = '';

  couponArray.map((coupon) => {
    let couponRank = parseInt(concessionPriority[coupon]);
    if (couponRank < rank) {
      rank = couponRank;
      preferredCoupon = coupon;
    }
  });
  return preferredCoupon;
};

export const filterDependents = (members) =>
  members.filter((member) => member.type !== USER_TYPE.PRIMARY);

export const debounce = (cb, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export const formatUserList = (users) =>
  users.map((user) => ({
    ...user,
    concession: checkConcessionPresent(user),
    relationshipType: findUserType(user.relationshipType),
    errors: {
      title: '',
      firstName: '',
      lastName: '',
      dob: '',
      relationshipType: '',
      email: '',
      mobilePhone: '',
      medicareNumber: '',
      ref: '',
      expiry: '',
      crn: '',
      cardType: ''
    }
  }));

// non-primary members
export const formatUserRelationshipList = (users, convertToRequestFormat = false) =>
  users.map((user) => ({
    ...user,
    relationshipType: convertToRequestFormat
      ? user.relationshipType === SPOUSE
        ? USER_TYPE.SPOUSE_PARTNER
        : USER_TYPE.CHILD_DEPENDANT
      : findUserType(user.relationshipType)
  }));

export const attachFieldDisableOnList = (memberList) => {
  return memberList.map((member) => {
    let { memberNumber, title, firstName, lastName, dob, relationshipType, email, mobilePhone } =
      member;
    if (memberNumber) {
      return {
        ...member,
        disable: {
          title: !isNullOrEmpty(title),
          firstName: !isNullOrEmpty(firstName),
          lastName: !isNullOrEmpty(lastName),
          dob: !isNullOrEmpty(dob),
          relationshipType: !isNullOrEmpty(relationshipType),
          email: !isNullOrEmpty(email),
          mobilePhone: !isNullOrEmpty(mobilePhone),
          concession: !isNullOrEmpty(member?.coupon?.crn)
        }
      };
    } else {
      return {
        ...member,
        disable: {
          title: false,
          firstName: false,
          lastName: false,
          dob: false,
          relationshipType: false,
          email: false,
          mobilePhone: false,
          concession: false
        }
      };
    }
  });
};

export const attachFieldDisableOnObject = (member) => ({
  ...member,
  disable: {
    title: false,
    firstName: false,
    lastName: false,
    dob: false,
    relationshipType: false,
    email: false,
    mobilePhone: false,
    concession: false
  }
});

export const getFilteredDependents = (planConfig, memberList) => {
  const { familyDetails, maxDependent, relationshipType } = planConfig;

  if (!familyDetails) {
    return [];
  }
  const allowedRelationshipTypes = new Set(
    relationshipType.map((relationship) => relationship.value)
  );
  return memberList
    .filter((member) => allowedRelationshipTypes.has(member.relationshipType))
    .slice(0, maxDependent);
};
