import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'assets/fonts/FilsonProRegular.otf';
import 'mediaQuery/mobile.css';
import 'mediaQuery/tablet.css';
import 'mediaQuery/windows.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import App from 'routes';

const root = ReactDOM.createRoot(document.getElementById('root'));

persistor.subscribe(() => {
  const { bootstrapped } = persistor.getState();
  if (bootstrapped) {
    // Rehydration is complete
    // Load your app or perform any necessary actions
    root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    );
  }
});

reportWebVitals();
