import styled from 'styled-components';
import question from 'assets/images/icon/question.png';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

export const Label = styled.label.attrs({
  className: 'required'
})`
  font-size: 12px;
  color: #5b5b5b;
`;

export const LabelNR = styled.label`
  color: #5b5b5b;
  font-size: 12px;
`;
export const NonMandatoryTxt = styled.span.attrs({
  className: 'filson-pro-regular'
})`
  font-size: 13px;
`;

export const MandatoryTxt = styled.span.attrs({
  className: 'required filson-pro-regular'
})`
  font-size: 13px;
`;

export const Heading = styled.p.attrs({
  className: 'filson-pro-bold'
})`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #2c2c2c;
  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

export const PointerHeading = styled.p.attrs({
  className: 'filson-pro-bold'
})`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #2c2c2c;
  cursor: pointer;
  margin-top: 3%;
  @media only screen and (max-width: 600px) {
    margin-top: 1%;
    font-size: 22px;
  }
`;

export const Title = styled.p.attrs({
  className: 'filson-pro-bold'
})`
  font-size: 20px;
  font-weight: bold;
  color: #2c2c2c;
`;

export const PlanLabel = styled.p.attrs({
  className: 'filson-pro-bold'
})`
  color: #005ba0;
  margin: 0;
`;

export const SwitchLabel = styled.span`
  color: #000000;
  margin-left: 4vw;
  margin-right: 2vw;
`;

export function dropDown(isDisabled = '', isBadAddress = false) {
  let borderStyle = '1px solid #ced4da';
  return {
    control: (base) => ({
      ...base,
      border: borderStyle,
      boxShadow: 'none',
      '&:hover': {
        border: borderStyle
      },
      height: 33.5,
      minHeight: 33.5,
      fontSize: '12px',
      cursor: 'pointer',
      backgroundColor: isDisabled ? '#e9ecef' : isBadAddress ? '#fee598' : undefined,
      color: isDisabled ? '#333333' : ''
    }),
    singleValue: (provided, state) => {
      const color = state.isDisabled ? '#333333' : undefined;
      return { ...provided, color };
    },
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? '#F1F9FC' : undefined,
      cursor: 'pointer',
      color: '#333333',
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? '#F1F9FC' : undefined
      }
    })
  };
}

export function paymentDropDown(isDisabled = '') {
  let borderStyle = '1px solid #ced4da';
  return {
    control: (base) => ({
      ...base,
      border: borderStyle,
      boxShadow: 'none',
      '&:hover': {
        border: borderStyle
      },
      height: 33.5,
      minHeight: 33.5,
      fontSize: '12px',
      cursor: 'pointer',
      backgroundColor: isDisabled ? '#e9ecef' : undefined,
      color: isDisabled ? '#333333' : ''
    }),
    singleValue: (provided, state) => {
      const color = state.isDisabled ? '#333333' : undefined;
      return { ...provided, color };
    },
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? '#F1F9FC' : undefined,
      color: '#333333',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? '#F1F9FC' : undefined
      }
    })
  };
}

export const Btn = styled(Button)`
  background-color: ${(props) => (props.isProcessing ? '#00a893' : '#ffffff')};
  color: ${(props) => (props.isProcessing ? '#ffffff' : '#00a893')};
  font-weight: bolder;
  border: 2px solid #00a893;
  border-radius: 5px;
  padding: 1vh 6vw 1vh 6vw;
  margin-top: 2vh;
  font-size: 14px;
  font-family: filson-pro-bold, sans-serif;
  text-transform: none;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

export const ModelBtn = styled(Button)`
  background-color: ${(props) => (props?.isProcessing ? '#00a893' : '#ffffff')};
  color: ${(props) => (props.isProcessing ? '#ffffff' : '#00a893')};
  font-weight: bolder;
  border: 2px solid #00a893;
  border-radius: 5px;
  padding: 1vh 3vw 1vh 3vw;
  margin-top: 4vh;
  font-size: 12px;
  text-transform: none;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

export const Skip = styled.button`
  background-color: #00a893;
  color: #000000;
  font-weight: 500;
  border: 2px solid #00a893;
  padding: 1vh 3vw 1vh 3vw;
  border-radius: 3px;
  float: right;
  display: ${(props) => (props.users.length === 0 ? 'none' : 'block')};
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

export const SkipInPortal = styled.button`
  background-color: #00a893;
  color: #000000;
  font-weight: 500;
  border: 2px solid #00a893;
  padding: 1vh 3vw 1vh 3vw;
  border-radius: 3px;
  float: right;
  display: ${(props) => (props.users.length === 0 ? 'none' : props.display ? 'none' : 'block')};
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #00a893;
    color: #ffffff;
  }
`;

export const DisabledBtn = styled.button`
  background-color: #ffffff;
  color: #ced4da;
  font-weight: bolder;
  border: 2px solid #ced4da;
  border-radius: 5px;
  padding: 1vh 6vw 1vh 6vw;
  margin-top: 2vh;
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox'
})`
  margin-top: 0.2rem;
  height: 15px;
  width: 15px;
  cursor: pointer;
`;

export const TermsWrap = styled.p.attrs({
  className: 'd-flex'
})`
  margin-top: 2vh;
`;

export const TermsContent = styled.span.attrs({
  className: 'filson-pro-regular'
})`
  margin-left: 1vw;
`;

export const Form = styled.div`
  font-size: 13px;
`;

export const BackBtn = styled.a.attrs({
  className: 'fa'
})`
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  font-size: 20px;
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;
  float: left;
  &:hover {
    background-color: #ddd;
    color: black;
  }
`;

export const QuestionMark = styled.img.attrs({
  src: question,
  align: 'right',
  'data-bs-toggle': 'tooltip',
  'data-bs-placement': 'bottom'
})`
  height: 15px;
  width: 15px;
  display: inline;
  cursor: pointer;
`;

export const disable = {
  opacity: 0.5,
  backgroundColor: '#00a893',
  color: '#ffffff'
};
export const active = {};

export const CouponBtn = styled.button.attrs({
  className: 'coupon-btn'
})`
  background-color: #00a893;
  opacity: ${(props) => (props.isCouponDisabled ? '0.5' : '1')};
  color: #ffffff;
  font-weight: bolder;
  border: 2px solid #00a893;
  border-radius: 5px;
  padding: 0.6vh 5vw 0.6vh 5vw;
  margin-left: 1vw;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
`;

export const RemoveCoupon = styled(CloseIcon)`
  color: #8b0000;
  stroke: #8b0000;
  stroke-width: 1;
  cursor: pointer;
`;

export const BoldText = styled.b`
  color: #00a893;
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const JustifyBetween = styled.div.attrs({
  className: 'd-flex justify-content-between mt-5'
})``;

export const GreenBlock = styled.div`
  background: #00a893;
  min-height: 20px;
  z-index: 2;
  position: sticky;
  top: 0;
`;
