import './style.css';
import PropTypes from 'prop-types';
import { FaSpinner } from 'react-icons/fa';

export function Loader() {
  return (
    <div>
      <div className="spinner" style={{ marginTop: '20vh' }}>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    </div>
  );
}

Loader.propTypes = {
  type: PropTypes.string
};

export function ButtonLoader() {
  const ICON_SIZE = 12;

  return (
    <div style={{ color: '#ffffff' }}>
      <FaSpinner size={ICON_SIZE} className="btn-spinner" />
      &nbsp;Loading
    </div>
  );
}
