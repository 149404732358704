import { useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonLoader } from 'components/Loader';

import { closeModel } from 'redux/model';
import { storeDisableNpCard, storeRequestNpCard } from 'redux/store';
import { disableNpCard } from 'redux/portal';
import { showSnackbar } from 'redux/snackbar';
import { useRequestNpCardMutation } from 'redux/rtkQuery/portalApiSlice';

import { PORTAL } from 'utils/constants/variable';

import { active, Btn, disable, dropDown } from 'styles';
import { BoldFont, ModelBody, ModelHeader, ModelTitle, RegularFont } from '../style';
import { Label } from '../SwitchPlan/style';

const RequestNPCard = () => {
  const { data } = useSelector((state) => state.model);
  const { dropdown } = useSelector((state) => state.chargebee);
  const { userFlow } = useSelector((state) => state.auth);
  const [requestNpCard, { isLoading }] = useRequestNpCardMutation();

  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { memberNumber, index } = data;

  const dispatch = useDispatch();

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const handleClose = () => dispatch(closeModel());

  const onSuccess = () =>
    dispatch(
      showSnackbar({
        severity: 'success',
        message: 'You have successfully submitted request for new NP Card'
      })
    );

  const handleSubmit = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      if (userFlow === PORTAL.CUSTOMER) {
        requestNpCard({ reasonCode: reasonCode.value })
          .unwrap()
          .then(() => onSuccess())
          .then(() => dispatch(disableNpCard()))
          .then(() => handleClose());
      } else {
        setIsProcessing(true);
        dispatch(
          storeRequestNpCard({
            memberNumber,
            reasonCode: reasonCode.value
          })
        )
          .then(() => onSuccess())
          .then(() => dispatch(storeDisableNpCard({ memberNumber, index })))
          .finally(() => {
            setIsProcessing(false);
            handleClose();
          });
      }
    }
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Request NP Card</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mt-2 mb-1">
          <div>
            <BoldFont>Please select a reason for requesting a New NP Card</BoldFont>
            <RegularFont>
              Please note: All new members including new family connections that are 16 <br /> year
              and over will receive a NP Card when joining. Please advise the
              <br /> customer that it may take up to 6 weeks to receive their new card by post.
              <br />
            </RegularFont>
          </div>
        </div>

        <div className="container font13px" style={{ width: '80%' }}>
          <div className="text-center">
            <Label htmlFor="reasonCode">Please select a reason:</Label>
          </div>
          <Select
            styles={dropDown('')}
            options={dropdown.reasonCodeForNPCard}
            value={reasonCode}
            isSearchable={false}
            onChange={handleReasonCodeChange}
          />
          <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
        </div>
        <div className="text-center mb-5">
          <Btn style={{ marginRight: '2vw' }} onClick={handleClose}>
            Cancel
          </Btn>
          {userFlow === PORTAL.CUSTOMER ? (
            <Btn
              onClick={handleSubmit}
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : 'Confirm'}
            </Btn>
          ) : (
            <Btn
              onClick={handleSubmit}
              style={isProcessing ? disable : active}
              isProcessing={isProcessing}
              disabled={isProcessing}>
              {isProcessing ? <ButtonLoader /> : 'Confirm'}
            </Btn>
          )}
        </div>
      </ModelBody>
    </>
  );
};

export default RequestNPCard;
