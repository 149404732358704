export const validEmailRegex = /^\w+([.-]?\w+)*([+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/;

export const validMobilePhoneRegex = /^04[0-9]{8}$/;

export const validHomePhoneRegex = /^0[0-9]{9}$/;

export const validPostcodeRegex = /^(0[289][0-9]{2})|([1-9][0-9]{3})$/;

export const validDateOfBirthRegex =
  /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/;

export const validHealthCareRegex = /[1-7][0-9]{8}[aAbBcChHjJkKlLsStTvVxX][a-zA-Z]?/;

export const validPensionerRegex = /[1-7][0-9]{8}[aAbBcChHjJkKlLsStTvVxX][a-zA-Z]?/;

export const validGoldRepatriationRegex =
  /[nNsSvVqQtTwW](([a-zA-Z][0-9]{1,6})|([a-zA-Z]{2}[0-9]{1,5})|([a-zA-Z]{3}[0-9]{1,4}))[a-zA-Z]?/;

export const validSeniorRegex = /[0-9]{5,9}/;

export const validCommonWealthRegex = /([1-7][0-9]{8}[aAbBcChHjJkKlLsStTvVxX][a-zA-Z])?/;

export const validDvaPensionCardRegex =
  /[nNsSvVqQtTwW](([a-zA-Z][0-9]{1,6})|([a-zA-Z]{2}[0-9]{1,5})|([a-zA-Z]{3}[0-9]{1,4}))[a-zA-Z]?/;

export const validDateRegex = /(^(0[1-9])|(1[0-2]))\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d{3})$/;

export const validateOnlyNumber = /^[0-9]+$/;
