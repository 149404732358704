import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { KEYWORD } from 'utils/constants/variable';
export const PrivateRoutes = () => {
  const { isUserLoggedIn } = useSelector((state) => state.auth);

  const location = useLocation();
  const path = location.pathname;
  const keySearch = (key) => path.includes(key);

  if (isUserLoggedIn) return <Outlet />;
  else if (keySearch(KEYWORD.MY_MEMBERSHIP)) return <Navigate to="/mymembership" />;
  else if (keySearch(KEYWORD.PORTAL)) return <Navigate to="/portal" />;
  else if (keySearch(KEYWORD.STORE)) return <Navigate to="/store" />;
  else return <Navigate to="/" />;
};

export const MembershipSearch = () => {
  const { searchMember } = useSelector((state) => state.auth);

  if (searchMember) return <Outlet />;
  else return <Navigate to="/store/hub" />;
};
