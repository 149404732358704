import { Button, Result } from 'antd';
import { validateURL } from 'utils/functions/security';
import { useSelector } from 'react-redux';
const NotFound = () => {
  const { url } = useSelector((state) => state.chargebee);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <a href={validateURL(url?.wordpressHome)} target="_self" rel="noreferrer">
          <Button type="primary">Go Back</Button>
        </a>
      }
    />
  );
};
export default NotFound;
