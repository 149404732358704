import React from 'react';
import { Route } from 'react-router-dom';
import lazyWithFallback from 'utils/lazyWithFallback';

const CreateMembership = lazyWithFallback(() => import('pages/membership'));
const PortalLogin = lazyWithFallback(() => import('pages/portal'));
const InstantRenewalLogin = lazyWithFallback(() => import('pages/instantRenewal'));
const Store = lazyWithFallback(() => import('pages/store/index'));
export const PublicRoutes = () => (
  <>
    <Route exact path="/" element={<CreateMembership />} />
    <Route path="/mymembership" element={<PortalLogin />} />
    <Route exact path="/portal" element={<InstantRenewalLogin />} />
    <Route exact path="/portal/:id" element={<InstantRenewalLogin />} />
    <Route exact path="/store" element={<Store />} />
  </>
);
