import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const ModelHeader = styled(Modal.Header).attrs({
  className: 'text-center'
})``;

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
`;

export const ModelBody = styled(Modal.Body).attrs({
  className: 'font13px'
})``;

export const ModelContent = styled.span``;
