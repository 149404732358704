import { createSlice } from '@reduxjs/toolkit';
import { NEW_MEMBER_DEFAULTS } from 'utils/constants/variable';
import { deepClone } from 'utils/functions/object';

const member = deepClone(NEW_MEMBER_DEFAULTS);

const addressObj = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  suburb: '',
  postcode: '',
  state: '',
  country: '',
  isBadAddress: false
};

const initialState = {
  index: 0,
  userId: 0,
  planName: '',
  isFamilyPlan: '',
  crn: '',
  email: '',
  mobilePhone: '',
  homePhone: '',
  healthOpt: '',
  seniorOpt: '',
  concession: null,
  cardType: null,
  familyList: [member],
  medicareNumber: '',
  ref: '',
  expiry: '',
  addressObj,
  opticalCustomer: false,
  membershipToggle: false,
  paymentToggle: false,
  affiliate: false
};

export const details = createSlice({
  name: 'details',
  initialState,
  reducers: {
    detailsUpdate: (state, action) => {
      const { data, address, memberList, membershipToggle, paymentToggle, affiliate } =
        action.payload;
      state.index = data?.index ?? state.index;
      state.userId = data?.userId ?? state.userId;
      state.planName = data?.planName ?? state.planName;
      state.isFamilyPlan = data?.isFamilyPlan ?? state.isFamilyPlan;
      state.concession = data?.concession ?? state.concession;
      // state.cardType = data?.cardType ?? state.cardType;
      // state.crn = data?.crn ?? state.crn;
      state.mobilePhone = data?.mobilePhone ?? state.mobilePhone;
      state.homePhone = data?.homePhone ?? state.homePhone;
      state.opticalCustomer = data?.opticalCustomer ?? state.opticalCustomer;
      state.medicareNumber = data?.medicareNumber ?? state.medicareNumber;
      state.ref = data?.ref ?? state.ref;
      state.expiry = data?.expiry ?? state.expiry;
      state.familyList = memberList;
      state.addressObj = address;
      state.membershipToggle = membershipToggle;
      state.paymentToggle = paymentToggle;
      state.affiliate = affiliate;
      // Set state.cardType to data?.cardType only if data?.userId is not equal to state.userId
      if (data?.userId !== state.userId || !data.concession) {
        state.cardType = data?.cardType;
        state.crn = data?.crn;
      } else {
        state.cardType = data?.cardType ?? state.cardType;
        state.crn = data?.crn ?? state.crn;
      }
    },
    upgradeResetCoupon: (state) => {
      state.couponCode = '';
      state.isCouponApplied = false;
    },
    updateMemberList: (state, action) => {
      state.familyList = action.payload;
    },
    updateContactDetails: (state, action) => {
      let { address, mobilePhone, homePhone, email } = action.payload;
      state.email = email;
      state.mobilePhone = mobilePhone;
      state.homePhone = homePhone;
      state.addressObj = address;
    },
    updateOpticalCustomer: (state, action) => {
      state.opticalCustomer = action.payload;
    },
    disableBadAddress: (state) => {
      state.addressObj = { ...state.addressObj, isBadAddress: false };
    },
    detailsReset: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  detailsUpdate,
  detailsReset,
  disableBadAddress,
  updateMemberList,
  upgradeResetCoupon,
  updateContactDetails,
  updateOpticalCustomer
} = details.actions;

export default details.reducer;
