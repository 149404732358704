import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { closeModel } from 'redux/model';
import { formReset } from 'redux/form';

import { ModelHeader, ModelBody } from '../style';
import { ModelBtn } from 'styles';

export default function ReturnToMembershipHub() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    handleClose();
    dispatch(formReset());
    navigate('/store/hub');
  };

  const handleClose = () => dispatch(closeModel());
  return (
    <>
      <ModelHeader closeButton></ModelHeader>
      <ModelBody>
        <div className="text-center mb-5">
          <p className="mt-5">Changes that you made may not be saved.</p>
          <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClick}>
            Ok
          </ModelBtn>
          <ModelBtn onClick={handleClose}>Cancel</ModelBtn>
        </div>
      </ModelBody>
    </>
  );
}
