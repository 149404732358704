import { useDispatch, useSelector } from 'react-redux';

import { closeModel } from 'redux/model';

import { Btn } from 'styles';
import { BoldFont, ModelBody, ModelHeader, ModelTitle, RegularFont } from '../style';

const Downgrade = () => {
  const { data } = useSelector((state) => state.model);
  const { membership } = useSelector((state) => state.portal);
  const { input, errors, setData, setErrors, planName } = data;
  const { config } = membership;

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(closeModel());
  };

  const handleSubmit = () => {
    setData(input);
    setErrors(errors);
    dispatch(closeModel());
  };

  const message = {
    Single: (
      <>
        This will remove all connections from your Family/Couple Membership <br /> and they will no
        longer be receiving Membership
        <br /> benefits as of {config.nextPaymentDueDate}
      </>
    ),
    Couple: (
      <>
        This will remove any dependents/children from your Family Membership
        <br /> and they will no longer be receiving Membership
        <br /> benefits as of {config.nextPaymentDueDate}
      </>
    )
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Membership Downgrade</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mt-2 mb-3">
          <div>
            <BoldFont>{`You have selected to change to a '${planName} Membership'.`}</BoldFont>
            <br />
            <RegularFont>{message[planName]}</RegularFont>
          </div>
        </div>
        <div className="text-center mb-5">
          <Btn style={{ marginRight: '2vw' }} onClick={handleCancel}>
            Cancel
          </Btn>
          <Btn onClick={handleSubmit}>Proceed</Btn>
        </div>
      </ModelBody>
    </>
  );
};

export default Downgrade;
