import { freeze } from 'utils/functions/object';

export const MEMBER_MAX_LIMIT = 10;

export const FAMILY = 'family';

export const SINGLE = 'single';

export const MEMBERSHIP_PLAN = 'membershipPlan';

export const PAYMENT_PLAN = 'paymentPlan';

export const TITLE = 'title';

export const TERMANDCONDITIONS = 'termsAndCondition';

export const IS_USER_LOGGED_IN = 'isUserLoggedIn';

export const SELECTED_STORE = 'selectedStore';

export const STORE_OPTIONS = 'storeOptions';

export const MOBILE_PHONE = 'mobilePhone';

export const HOME_PHONE = 'homePhone';

export const CRN = 'crn';

export const DOB = 'dob';

export const NOT_DEFINED = 'ND';

export const FIRST_NAME = 'firstName';

export const LAST_NAME = 'lastName';

export const OPTICAL_CUSTOMER = 'opticalCustomer';

export const CONCESSION_FLAG = 'concession';

export const CONCESSION_TYPE = 'cardType';

export const COUPON = 'coupon';

export const EMPLOYEE = 'employee';

export const STATIC = 'static';

export const PERIOD = freeze({
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
});

export const FREQUENCY = freeze({
  MONTHLY: 1,
  QUARTERLY: 3,
  HALF_YEARLY: 2
});

export const SECTION = freeze({
  PERSONAL: 'personalDetails',
  CONTACT: 'contactDetails',
  FAMILY: 'familyDetails'
});

export const RE_ESTIMATE = true;

export const SKIP = false;

export const PAYFLOW_PRO = 'PFG';

export const EXPRESS = 'PEC';

export const PAYPAL = 'PAYPAL';

export const POINT_OF_SALE = 'POS';

export const POS = 'POS';

export const PRIMARY = 'primary';

export const SPOUSE = 'spouse';

export const CHILD = 'child';

export const USER_RELATIONSHIP = 'relationship';

export const PREFERRED_STORE = 'preferredStore';

export const COLOR_CODE = freeze({
  ACTIVE: '#00a893',
  EXPIRED: '#ff0000',
  'PAYMENT DUE': '#ffa500'
});

export const PORTAL = freeze({
  CUSTOMER: 'customer',
  STORE: 'store',
  JOIN_MEMBERSHIP: 'join_membership'
});

export const KEYWORD = freeze({
  PORTAL: 'portal',
  STORE: 'store',
  MY_MEMBERSHIP: 'mymembership'
});

export const CONCESSION = freeze({
  HEALTH: 'HC',
  SENIOR: 'SC'
});
export const RELATIONSHIP = freeze({
  PRIMARY: 'Primary',
  SPOUSE_PARTNER: 'Spouse/Partner',
  CHILD_DEPENDANT: 'Dependant/Child'
});

export const RELATIONSHIP_MAPPING = freeze({
  PR: 'Primary',
  SP: 'Spouse/Partner',
  CD: 'Dependant/Child'
});

export const USER_TYPE = freeze({
  PRIMARY: 'PR',
  SPOUSE_PARTNER: 'SP',
  CHILD_DEPENDANT: 'CD'
});

export const MEMBERSHIP_STATUS = freeze({
  FINANCIAL: 'FINANCIAL',
  NON_FINANCIAL: 'NON-FINANCIAL'
});

export const OP = freeze({
  RENEW: 'RENEW',
  UPDATE_MEMBERSHIP: 'UPDATE_MEMBERSHIP',
  PAY_NOW: 'PAY_NOW'
});

export const SUB_TYPE = freeze({
  NEW: 'NEW',
  REACTIVE: 'RENEW'
});

export const PORTAL_TYPE = freeze({
  CUSTOMER_PORTAL: 'CUSTOMER_PORTAL',
  STORE_PORTAL: 'STORE_PORTAL',
  QUICK_RENEWAL: 'QUICK_RENEWAL'
});

export const OPERATION_TYPE = freeze({
  UPDATE: 'UPDATE',
  RENEWAL: 'RENEWAL',
  CHANGE_SUBSCRIPTION: 'CHANGE_SUBSCRIPTION',
  PAY_NOW: 'PAY_NOW'
});

export const COUNTRY = freeze({
  AUSTRALIA: 'AU'
});

export const PAYMENT_ACTION = freeze({
  GET: 'GET',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  MARK_PREFERRED: 'MAKE_PRIMARY',
  DELETE: 'DELETE'
});

export const PURCHASE_STATUS = freeze({
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING'
});

export const ACTION = freeze({
  VIEW_MY_MEMBERSHIP: 'view_my_membership',
  VIEW_MY_DETAILS: 'view_my_details',
  EDIT_MY_DETAILS: 'edit_my_details',
  VIEW_FAMILY_DETAILS: 'view_family_details',
  EDIT_FAMILY_DETAILS: 'edit_family_details',
  VIEW_MEMBERSHIP_DETAILS: 'view_membership_details',
  EDIT_MEMBERSHIP_DETAILS: 'edit_membership_details',
  VIEW_MEMBERSHIP_PLAN: 'view_membership_plan',
  EDIT_MEMBERSHIP_PLAN: 'edit_membership_plan',
  RENEW_MEMBERSHIP: 'renew_membership',
  PAY_NOW: 'pay_now',
  VIEW_BILLING_DETAILS: 'view_billing_details',
  EDIT_BILLING_DETAILS: 'edit_billing_details',
  VIEW_COMMUNICATION_DETAILS: 'view_communication_details',
  EDIT_COMMUNICATION_DETAILS: 'edit_communication_details',
  LIMITED_COMMUNICATION_DETAILS: 'limited_communication_details'
});

export const SUBSCRIPTION_STATUS = freeze({
  ACTIVE: 'active',
  EXPIRED: 'expired'
});

export const SEVERITY = freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
});

export const PRE_64 = 'Pre64';
export const BOOPA_FLAG = 'BUPA Conversion';

export const INVALID_USER_INPUT = {
  title: 'Membership Account Not Found',
  message:
    'We were unable to find a membership account matching the information you have provided.' +
    ' Please contact Member Services on 1300 667 676 or visit us in store.'
};

export const NA_SUPPORT_MESSAGE =
  'Something went wrong, If you are continuing to have problems. ' +
  'please contact Member Services during business hours on 1300 667 676 or visit your nearest store.';

export const NON_ACTIVE_SUBSCRIPTION =
  'You currently do not have any active membership,' +
  ' please reactivate your membership to add/remove dependents or contact membership services team for help';

export const NEW_MEMBER_DEFAULTS = {
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  relationshipType: '',
  email: '',
  mobilePhone: '',
  medicareNumber: '',
  ref: '',
  expiry: '',
  concession: null,
  errors: {
    title: '',
    firstName: '',
    lastName: '',
    dob: '',
    relationshipType: '',
    email: '',
    mobilePhone: '',
    medicareNumber: '',
    ref: '',
    expiry: '',
    crn: '',
    cardType: ''
  }
};

export const PORTAL_MEMBERSHIP_STATUS = freeze({
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  PAYMENT_DUE: 'PAYMENT DUE'
});

export const CARD_TYPE = freeze({
  CONCESSION: 'CONCESSION_CARD',
  HEALTH_CARE: 'HEALTH_CARE_CARD',
  SA_SENIORS: 'SA_SENIORS_CARD',
  DVA_PENSION: 'DVA_PENSION_CARD',
  PENSIONER: 'PENSIONER_CARD',
  COMMONWEALTH_SENIORS: 'COMMONWEALTH_SENIORS_CARD',
  GOLD_REPATRIATION: 'GOLD_REPATRIATION_CARD'
});
