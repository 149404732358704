import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NP_ENGINE } from 'settings/dev';
import axios from 'axios';
import api from 'utils/functions/api';
import { preferredPaymentSort } from '../utils/functions';

const initialState = {
  id: '',
  name: '',
  price: 0,
  discount: 0,
  amountWithoutDiscount: 0,
  coupon: '',
  isLoading: false,
  futureUpdate: false,
  annualReport: false,
  plasticMembershipCard: false,
  couponError: '',
  users: [],
  validDate: '',
  nextPaymentDue: '',
  paymentGateway: '',
  isValidCoupon: false,
  isEstimated: false,
  couponApplied: false,
  tokenRefresh: false,
  existingPayments: [],
  selectedPaymentIndex: 0,
  invoices: []
};

export const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearCouponError: (state) => {
      state.couponError = '';
    },
    couponStatus: (state, action) => {
      state.isValidCoupon = action.payload.coupon;
    },
    estimate: (state, action) => {
      state.isEstimated = action.payload;
    },
    reEstimate: (state) => {
      state.isEstimated = false;
    },
    couponUpdated: (state, action) => {
      state.couponApplied = action.payload.isApplied;
      state.coupon = action.payload.coupon;
    },
    clearAppliedCoupon: (state) => {
      state.isEstimated = false;
      state.couponApplied = false;
      state.coupon = '';
    },
    paymentCompleted: (state, action) => {
      let { users, validDate, nextPaymentDueDate } = action.payload;
      state.users = users;
      state.validDate = validDate;
      state.nextPaymentDue = nextPaymentDueDate;
    },
    updatePaymentGateway: (state, action) => {
      state.paymentGateway = action.payload;
    },
    expressReset: (state) => {
      state.tokenRefresh = !state.tokenRefresh;
    },
    updateExistingPayments: (state, action) => {
      let { paymentSources, invoices } = action.payload;
      state.existingPayments = preferredPaymentSort([...paymentSources]);
      state.invoices = [...invoices];
    },
    updatePaymentIndex: (state, action) => {
      state.selectedPaymentIndex = action.payload;
    },
    paymentReset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEstimate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEstimate.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.price = action.payload.price;
      state.discount = action.payload.discount;
      state.amountWithoutDiscount = action.payload.amountWithoutDiscount;
      state.isLoading = false;
    });
    builder.addCase(getEstimate.rejected, (state, action) => {
      state.isLoading = false;
      state.couponError = action.payload;
    });
  }
});

export const getEstimate = createAsyncThunk('payment/getEstimates', async (payload, thunkAPI) => {
  try {
    const resp = await axios.post(`${NP_ENGINE}/common/estimate`, payload);
    return resp.data;
  } catch ({ response }) {
    let { data } = response;
    return thunkAPI.rejectWithValue(data.message);
  }
});

export const processPayment = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/signup/`, payload).then((response) => {
    dispatch(paymentCompleted(response));
    return response;
  });

export const processPaymentFromStore = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/store/createMembership`, payload).then((response) => {
    dispatch(paymentCompleted(response));
    return response;
  });

export const createIntent = (payload) => () =>
  api.post(NP_ENGINE, `/common/token`, payload).then((response) => {
    return response;
  });

export const existingPaymentAction = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/customer/paymentMethods`, payload).then((response) => {
    dispatch(updateExistingPayments(response));
    return response;
  });

// Action creators are generated for each case reducer function
export const {
  clearCouponError,
  couponStatus,
  couponUpdated,
  clearAppliedCoupon,
  estimate,
  reEstimate,
  updatePaymentGateway,
  paymentCompleted,
  updateExistingPayments,
  updatePaymentIndex,
  expressReset,
  paymentReset
} = payment.actions;

export default payment.reducer;
