import { apiSlice } from 'redux/rtkQuery/apiSlice';

export const estimateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEstimateForUpgrade: builder.mutation({
      query: (payload) => ({
        url: '/common/estimate',
        method: 'POST',
        body: { ...payload }
      }),
      keepUnusedDataFor: 3
    })
  })
});

export const { useGetEstimateForUpgradeMutation } = estimateApiSlice;
