import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
  overlay: false
};

export const form = createSlice({
  name: 'form',
  initialState,
  reducers: {
    stepIncrement: (state) => {
      state.step = state.step + 1;
    },
    stepDecrement: (state) => {
      state.step = state.step - 1;
    },
    showOverlay: (state) => {
      state.overlay = true;
    },
    hideOverlay: (state) => {
      state.overlay = false;
    },
    formReset: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { stepIncrement, stepDecrement, showOverlay, hideOverlay, formReset } = form.actions;

export default form.reducer;
