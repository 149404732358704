import Modal from 'react-bootstrap/Modal';
import { Heading, Title, MemberNumber, UserName, UserWrap } from './style';
import { useSelector } from 'react-redux';
import { ellipsis, toTitleCase } from 'utils/functions/text';
import { RELATIONSHIP } from 'utils/constants/variable';

export default function MembershipDetails() {
  const { title, paymentPlanName, validUntil, nextPaymentDue, users } = useSelector(
    (state) => state.portal
  );
  const getPaymentPlan = () => {
    return `${toTitleCase(paymentPlanName)} over 12 months subscription`;
  };

  const getUserType = (type) =>
    type === 'PR'
      ? 'Primary'
      : type === 'SP'
      ? RELATIONSHIP.SPOUSE_PARTNER
      : RELATIONSHIP.CHILD_DEPENDANT;

  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <Title>Membership Details</Title>
          <Heading>
            <b>{title}</b>
          </Heading>
          <div className="text-center filson-pro-bold mb-3">
            <b className="d-block">{getPaymentPlan()}</b>
            <b className="d-block">{`Membership Benefit until ${validUntil}`}</b>
            {nextPaymentDue && <b className="d-block">{`Next payment due: ${nextPaymentDue}`}</b>}
          </div>
          {users.map((user, index) => (
            <UserWrap key={index}>
              <UserName className="text-align-center">{`${ellipsis(
                `${user.firstName} ${user.lastName}`
              )}`}</UserName>
              <MemberNumber className="text-align-center">{`Member #${
                user.memberNumber
              } (${getUserType(user.relationshipType)})`}</MemberNumber>
            </UserWrap>
          ))}
          <br />
          <p className="text-center filson-pro-regular font12px mb-5">
            If you have any questions relating to your Membership,
            <br /> please contact Member Services on 1300 667 676.
          </p>
        </div>
      </Modal.Body>
    </>
  );
}
