import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { closeModel } from 'redux/model';

import { ellipsis } from 'utils/functions/text';

import { Btn, dropDown } from 'styles';
import { BoldFont, ModelBody, ModelHeader, ModelTitle, RegularFont } from '../style';
import { Label } from '../SwitchPlan/style';

const RemoveFamilyMember = () => {
  const { data } = useSelector((state) => state.model);
  const { dropdown } = useSelector((state) => state.chargebee);
  const { index, memberList, setMemberList, setCounter, setRemovedUserList } = data;

  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');
  const selectedUser = memberList[index];
  const userName = ellipsis(`${selectedUser.firstName} ${selectedUser.lastName}`);
  const relationshipType =
    selectedUser.relationshipType === 'spouse' ? 'Spouse/Partner' : 'Dependant/Child';

  const dispatch = useDispatch();

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const handleCancel = () => {
    dispatch(closeModel());
  };

  const handleSubmit = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      let userList = memberList.slice();
      setRemovedUserList((prevState) => ({
        ...prevState,
        [selectedUser.memberNumber]: reasonCode.value
      }));
      userList.splice(index, 1);
      setMemberList(userList);
      setCounter(userList.length);
      dispatch(closeModel());
    }
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Remove Family Member</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mt-2 mb-1">
          <div>
            <BoldFont>Are you sure you would like to remove this Family Member?</BoldFont>
            <br />
            <RegularFont>
              This will remove the selected Family Member below from your Membership and <br /> will
              cancel all Membership Benefits immediately.
              <br />
            </RegularFont>
          </div>

          <div>
            <span className="filson-pro-bold">{`${userName} Member #${selectedUser.memberNumber} (${relationshipType})`}</span>
          </div>
        </div>

        <div className="container font13px" style={{ width: '30%' }}>
          <div className="text-center">
            <Label htmlFor="reasonCode">Please select a reason:</Label>
          </div>
          <Select
            styles={dropDown('')}
            options={dropdown.reasonCodeForCancel}
            value={reasonCode}
            isSearchable={false}
            onChange={handleReasonCodeChange}
          />
          <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
        </div>
        <div className="text-center mb-5">
          <Btn style={{ marginRight: '2vw' }} onClick={handleCancel}>
            Cancel
          </Btn>
          <Btn onClick={handleSubmit}>Confirm</Btn>
        </div>
      </ModelBody>
    </>
  );
};

export default RemoveFamilyMember;
