import { freeze } from 'utils/functions/object';

export const OPTIONS = freeze([
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]);

export const MEMBERSHIP_PLANS = freeze([
  { value: 'single', label: 'Single' },
  { value: 'family', label: 'Family' }
]);

export const STATES = freeze([
  { value: 'ACT', label: 'ACT' },
  { value: 'NSW', label: 'NSW' },
  { value: 'NT', label: 'NT' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' }
]);

export const TITLES = freeze([
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Master', label: 'Master' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Professor', label: 'Professor' },
  { value: 'Doctor', label: 'Doctor' },
  { value: 'Lieutenant', label: 'Lieutenant' },
  { value: 'Not Provided', label: 'Not Provided' }
]);

export const CHILD_RELATIONSHIP = freeze([{ value: 'child', label: 'Dependant/Child' }]);
