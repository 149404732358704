import { ModelBody, ModelHeader, ModelTitle } from '../style';
import { active, disable, LabelNR, ModelBtn } from 'styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateExistingPayments } from 'redux/payment';
import { ButtonLoader } from 'components/Loader';
import { closeModel } from 'redux/model';
import { showSnackbar } from 'redux/snackbar';
import { usePaymentActionMutation } from 'redux/rtkQuery/paymentApiSlice';
import { PAYFLOW_PRO, PAYMENT_ACTION } from 'utils/constants/variable';

const EditPayment = () => {
  const { data } = useSelector((state) => state.model);
  const { existingPayments } = useSelector((state) => state.payment);
  const [paymentAction] = usePaymentActionMutation();

  const { paymentSourceId, memberNumber } = data;
  const selectedPayment = existingPayments.find((p) => p.id === paymentSourceId);
  const { maskedNumber, cardExpiryMonth, cardExpiryYear } = selectedPayment.source;

  const [month, setMonth] = useState(cardExpiryMonth);
  const [year, setYear] = useState(cardExpiryYear);
  const [errors, setErrors] = useState({
    month: '',
    year: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => setErrors({}), [month, year]);

  const dispatch = useDispatch();

  const handleChange = (ev) => {
    ev.preventDefault();
    const { name, value } = ev.target;

    if (name === 'month') {
      setMonth(value);
    } else if (name === 'year') {
      setYear(value);
    }
  };

  const handleClose = () => dispatch(closeModel());

  const precheck = () => {
    let status = true;
    let err = {};
    if (!(month >= 1 && month <= 12)) {
      err.month = 'Invalid expiry month';
      status = false;
    }
    if (year.toString().length !== 4) {
      err.year = 'Invalid expiry year';
      status = false;
    }
    setErrors(err);
    return status;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (precheck()) {
      setIsProcessing(true);
      const payload = {
        memberNumber,
        action: PAYMENT_ACTION.UPDATE,
        paymentDetails: {
          id: paymentSourceId,
          paymentMethod: PAYFLOW_PRO,
          cardExpiryMonth: month,
          cardExpiryYear: year
        }
      };
      paymentAction(payload)
        .unwrap()
        .then((response) => {
          dispatch(updateExistingPayments(response));
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          dispatch(showSnackbar({ severity: 'error', message: err?.data?.message }));
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Edit Payment details</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="mb-5" style={{ width: '60%', margin: 'auto', padding: '10px' }}>
          <div className="">
            <LabelNR htmlFor="masked_number">Card Number</LabelNR>
            <input
              type="text"
              className="form-control"
              name="masked_number"
              value={maskedNumber}
              autoComplete="off"
              disabled={true}
            />
          </div>
          <div className="flex-cond d-wrap flex-wrap justify-content-between filson-pro-regular mt-3">
            <div className="">
              <LabelNR htmlFor="month">Expiry Month</LabelNR>
              <input
                type="number"
                className="form-control"
                name="month"
                value={month}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="error">{errors.month ?? ''}</span>
            </div>
            <div className="">
              <LabelNR htmlFor="year">Expiry Year</LabelNR>
              <input
                type="number"
                className="form-control"
                name="year"
                value={year}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="error">{errors.year ?? ''}</span>
            </div>
          </div>
          <div className="text-center">
            <ModelBtn
              style={isProcessing ? disable : active}
              isProcessing={isProcessing}
              disabled={isProcessing}
              onClick={handleSubmit}>
              {isProcessing ? <ButtonLoader /> : 'Save'}
            </ModelBtn>
          </div>
        </div>
      </ModelBody>
    </>
  );
};

export default EditPayment;
