import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  membershipPlanName: '',
  paymentPlan: {},
  users: [],
  validDate: '',
  paidVia: '',
  title: '',
  nextPaymentDueDate: '',
  isConcessionApplied: false,
  name: ''
};

export const thankYou = createSlice({
  name: 'thankYou',
  initialState,
  reducers: {
    onSuccess: (state, action) => {
      let {
        membershipPlanName,
        paymentPlan,
        users,
        validTillDate,
        paidVia,
        title,
        nextPaymentDueDate,
        isConcessionApplied,
        name
      } = action.payload;
      state.membershipPlanName = membershipPlanName;
      state.paymentPlan = paymentPlan;
      state.users = users;
      state.validDate = validTillDate;
      state.paidVia = paidVia;
      state.title = title;
      state.nextPaymentDueDate = nextPaymentDueDate;
      state.isConcessionApplied = isConcessionApplied;
      state.name = name;
    },
    thankYouReset: () => {
      return initialState;
    }
  }
});

export const { onSuccess, thankYouReset } = thankYou.actions;

export default thankYou.reducer;
