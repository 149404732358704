import { ModelBody, ModelHeader, ModelTitle } from '../style';
import { active, Btn, disable, LabelNR } from 'styles';
import { useEffect, useState } from 'react';
import { fetchPlanList } from 'redux/membership';
import { portalLoggingIn, updateMembership, updateBadAddress } from 'redux/portal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonLoader } from 'components/Loader';
import { showSnackbar } from 'redux/snackbar';
import { closeModel } from 'redux/model';
import { useLoginMutation } from 'redux/rtkQuery/authApiSlice';
import { loadPolicy, setCredentials } from 'redux/auth';
import { getUserTypeFromToken } from 'utils/functions';
import { findUserPolicy } from 'utils/functions/rules';

const VerifyOtp = () => {
  const { data } = useSelector((state) => state.model);
  const [otp, setOtp] = useState('');
  const [otpTimer, setOtpTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isResending, setIsResending] = useState(false); // Track resend operation
  const [otpErr, setOtpErr] = useState('');
  const [login, { isLoading }] = useLoginMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setOtpErr('');
  }, [otp]);

  useEffect(() => {
    let timer;

    if (isTimerActive && otpTimer > 0) {
      timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
    } else if (otpTimer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isTimerActive, otpTimer]);

  const startTimer = () => {
    if (!isTimerActive) {
      setOtpTimer(30); // Reset the timer to 30 seconds
      setIsTimerActive(true);
    }
  };

  const resendVerificationCode = () => {
    if (!isResending) {
      debounceResend(); // Use debounce to prevent multiple clicks
    }
  };

  const debounceResend = () => {
    setIsResending(true); // Set loading state
    setOtp(''); // Reset OTP value

    dispatch(portalLoggingIn(data))
      .then(() => {
        setIsResending(false); // Reset loading state when the operation is complete
        dispatch(
          showSnackbar({
            message: 'OTP verification code successfully resent',
            severity: 'success'
          })
        );
      })
      .catch((e) => {
        setIsResending(false); // Reset loading state on error
        setOtpErr(e.message);
      });

    startTimer(); // Start the timer when the code is resent
  };

  const loadMembershipDetails = (membership) => {
    dispatch(fetchPlanList())
      .then(() => dispatch(updateMembership(membership)))
      .then(() => dispatch(updateBadAddress(membership?.config?.isBadAddress)))
      .then(() => dispatch(closeModel()))
      .then(() => navigate('/mymembership/home'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.length !== 0) {
      let payload = { ...data, otpCode: otp };
      login(payload)
        .unwrap()
        .then(async (response) => {
          let { data, token } = response;
          await dispatch(setCredentials(token));
          await dispatch(loadPolicy(findUserPolicy(data, getUserTypeFromToken(data, token))));
          await loadMembershipDetails(data);
        })
        .catch((err) => {
          setOtpErr(err?.data?.message);
        });
    } else {
      setOtpErr('Please enter OTP code');
    }
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Verification Code</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center font13px filson-pro-regular mb-5">
          <p className="mt-2">
            To protect the privacy of your account, we have sent you a unique code via SMS. <br />
            <span className="red">
              If you have not provided a mobile number, please contact Member Services on 1300 667
              676 to enable online access.
            </span>
          </p>
          <p className="mt-2">
            Please enter the verification code below and select &apos;Verify&apos; to continue.
          </p>

          <div className="d-flex align-center">
            <div className="form-member-group">
              <LabelNR htmlFor="otp">Verification Code</LabelNR>
              <input
                type="text"
                className="form-control font13px text-center"
                name="otp"
                value={otp}
                maxLength="10"
                autoComplete="off"
                onChange={(e) => setOtp(e.target.value)}
              />

              <span className="error">{otpErr}</span>
            </div>
          </div>
          <p className="mt-3">
            Did not receive code?{' '}
            {isTimerActive ? (
              `Resend code in ${otpTimer} seconds`
            ) : (
              <u className="url-highlight" onClick={resendVerificationCode} disabled={isResending}>
                Click here to Resend
              </u>
            )}
          </p>

          <div className="text-center mb-3">
            <Btn
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}
              onClick={handleSubmit}>
              {isLoading ? <ButtonLoader /> : 'Verify'}
            </Btn>
          </div>
        </div>
      </ModelBody>
    </>
  );
};

export default VerifyOtp;
