import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { closeModel } from 'redux/model';

import { updateBadAddress } from '../../redux/portal';

import TermsAndConditions from './TermsAndConditions';
import PaymentTermsAndConditions from './TermsAndConditions/payment';
import MembershipDetails from './MembershipDetails';
import MembershipChange from './MembershipChange';

import RenewalLogin from './RenewalLogin';
import StoreMembershipDetails from './StoreMembershipDetails';
import MembershipNotFound from './MembershipNotFound';
import ReturnToMembershipHub from './ReturnToMembershipHub';
import ComingSoon from './ComingSoon';
import SwitchPlan from './SwitchPlan';
import EditPayment from './EditPayment';
import VerifyOtp from './VerifyOtp';
import Warning from './Warning';
import AddressUpdate from './AddressUpdate';
import StoreAddressUpdate from './AddressUpdate/staffPortal';
import Logout from './Logout';
import CancelMembership from './CancelMembership';
import Downgrade from './Downgrade';
import Upgrade from './Upgrade';
import RemoveFamilyMember from './RemoveFamilyMember';
import RequestNPCard from './RequestNPCard';
import BadAddress from './BadAddress';
import SelfRemove from './SelfRemove';
import MembershipScheduled from './MembershipScheduled';

export const MODAL_TYPES = {
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  PAYMENT_TERMS_AND_CONDITIONS: 'PAYMENT_TERMS_AND_CONDITIONS',
  MEMBERSHIP_DETAILS: 'MEMBERSHIP_DETAILS',
  MEMBERSHIP_CHANGE: 'MEMBERSHIP_CHANGE',
  STORE_MEMBERSHIP_DETAILS: 'STORE_MEMBERSHIP_DETAILS',
  RENEWAL_LOGIN: 'RENEWAL_LOGIN',
  MEMBERSHIP_NOT_FOUND: 'MEMBERSHIP_NOT_FOUND',
  RETURN_TO_HUB: 'RETURN_TO_HUB',
  COMING_SOON: 'COMING_SOON',
  SWITCH_PLAN: 'SWITCH_PLAN',
  SWITCH_PLAN_PORTAL: 'SWITCH_PLAN_PORTAL',
  EDIT_PAYMENT_SOURCE: 'EDIT_PAYMENT_SOURCE',
  OTP_VERIFICATION: 'OTP_VERIFICATION',
  WARNING: 'Warning',
  ADDRESS_UPDATE: 'ADDRESS_UPDATE',
  STORE_ADDRESS_UPDATE: 'STORE_ADDRESS_UPDATE',
  PORTAL_LOGOUT: 'PORTAL_LOGOUT',
  PORTAL_CANCEL_MEMBERSHIP: 'PORTAL_CANCEL_MEMBERSHIP',
  PORTAL_SELF_REMOVE_MEMBERSHIP: 'PORTAL_SELF_REMOVE_MEMBERSHIP',
  UPGRADE: 'UPGRADE',
  DOWNGRADE: 'DOWNGRADE',
  REMOVE_FAMILY_MEMBER: 'REMOVE_FAMILY_MEMBER',
  REQUEST_NP_CARD: 'REQUEST_NP_CARD',
  BAD_ADDRESS: 'BAD_ADDRESS',
  MEMBERSHIP_SCHEDULED: 'MEMBERSHIP_SCHEDULED'
};

export function Model() {
  const { type, isOpened, backdrop } = useSelector((state) => state.model);
  const { badAddress } = useSelector((state) => state.portal);
  const dispatch = useDispatch();

  const selectModal = (modalType) => {
    switch (modalType) {
      case MODAL_TYPES.TERMS_AND_CONDITIONS:
        return <TermsAndConditions />;
      case MODAL_TYPES.PAYMENT_TERMS_AND_CONDITIONS:
        return <PaymentTermsAndConditions />;
      case MODAL_TYPES.MEMBERSHIP_DETAILS:
        return <MembershipDetails />;
      case MODAL_TYPES.MEMBERSHIP_CHANGE:
        return <MembershipChange />;
      case MODAL_TYPES.STORE_MEMBERSHIP_DETAILS:
        return <StoreMembershipDetails />;
      case MODAL_TYPES.RENEWAL_LOGIN:
        return <RenewalLogin />;
      case MODAL_TYPES.MEMBERSHIP_NOT_FOUND:
        return <MembershipNotFound />;
      case MODAL_TYPES.RETURN_TO_HUB:
        return <ReturnToMembershipHub />;
      case MODAL_TYPES.COMING_SOON:
        return <ComingSoon />;
      case MODAL_TYPES.SWITCH_PLAN:
        return <SwitchPlan />;
      case MODAL_TYPES.EDIT_PAYMENT_SOURCE:
        return <EditPayment />;
      case MODAL_TYPES.OTP_VERIFICATION:
        return <VerifyOtp />;
      case MODAL_TYPES.WARNING:
        return <Warning />;
      case MODAL_TYPES.ADDRESS_UPDATE:
        return <AddressUpdate />;
      case MODAL_TYPES.STORE_ADDRESS_UPDATE:
        return <StoreAddressUpdate />;
      case MODAL_TYPES.PORTAL_LOGOUT:
        return <Logout />;
      case MODAL_TYPES.PORTAL_CANCEL_MEMBERSHIP:
        return <CancelMembership />;
      case MODAL_TYPES.PORTAL_SELF_REMOVE_MEMBERSHIP:
        return <SelfRemove />;
      case MODAL_TYPES.UPGRADE:
        return <Upgrade />;
      case MODAL_TYPES.DOWNGRADE:
        return <Downgrade />;
      case MODAL_TYPES.REMOVE_FAMILY_MEMBER:
        return <RemoveFamilyMember />;
      case MODAL_TYPES.REQUEST_NP_CARD:
        return <RequestNPCard />;
      case MODAL_TYPES.BAD_ADDRESS:
        return <BadAddress />;
      case MODAL_TYPES.MEMBERSHIP_SCHEDULED:
        return <MembershipScheduled />;
      default:
        return;
    }
  };

  const handleModelHide = () => {
    badAddress && dispatch(updateBadAddress(false));
    dispatch(closeModel());
  };

  return (
    <>
      <Modal
        className="modal-lg filson-pro-book z-1000"
        backdrop={backdrop}
        show={isOpened}
        onHide={handleModelHide}>
        {selectModal(type)}
      </Modal>
    </>
  );
}
