import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonLoader } from 'components/Loader';

import { updateEstimate } from 'redux/estimate';
import { paymentPlanUpdated } from 'redux/portal';
import { showSnackbar } from 'redux/snackbar';
import { closeModel } from 'redux/model';
import { useGetEstimateForUpgradeMutation } from 'redux/rtkQuery/estimateApiSlice';

import { getPrimarySubscriptionId } from 'utils/functions';

import { ModelHeader, ModelBody } from '../style';
import { active, disable, ModelBtn } from 'styles';

export default function MembershipChange() {
  const { membership } = useSelector((state) => state.portal);
  const { data } = useSelector((state) => state.model);
  const [getEstimateForUpgrade, { isLoading }] = useGetEstimateForUpgradeMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (ev) => {
    ev.preventDefault();

    getEstimateForUpgrade(data)
      .unwrap()
      .then((response) => {
        dispatch(updateEstimate(response));
        dispatch(
          paymentPlanUpdated({
            paymentPlan: { id: data.id },
            subscriptionId: getPrimarySubscriptionId(membership.users)
          })
        );
        handleClose();
        navigate('/store/hub/payment');
      })
      .catch((err) => {
        console.log(err);
        dispatch(showSnackbar({ severity: 'error', message: err?.data?.message }));
      });
  };

  const handleClose = () => dispatch(closeModel());

  return (
    <>
      <ModelHeader closeButton></ModelHeader>
      <ModelBody>
        <div className="text-center mb-5">
          <p className="font12px mt-5">
            You have selected to make changes to your Membership Plan <br /> and/or Payment Plan.
            You will be redirected to the Payment <br /> Page to complete this process.
          </p>
          <ModelBtn
            onClick={handleClick}
            style={isLoading ? disable : active}
            isProcessing={isLoading}
            disabled={isLoading}>
            {isLoading ? <ButtonLoader /> : 'OK'}
          </ModelBtn>
        </div>
      </ModelBody>
    </>
  );
}
