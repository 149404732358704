import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  isOpened: false,
  data: '',
  backdrop: true
};
const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    openModel: (state, action) => {
      state.isOpened = true;
      state.type = action.payload.type;
      state.data = action.payload.data;
      state.backdrop = action.payload.backdrop ?? true; // to avoid pop-up close if users clicks outside
    },
    closeModel: () => initialState,
    modelReset: () => {
      return initialState;
    }
  }
});

export const { openModel, closeModel, modelReset } = modelSlice.actions;

export default modelSlice.reducer;
