import { apiSlice } from 'redux/rtkQuery/apiSlice';

export const portalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateInfo: builder.mutation({
      query: (payload) => ({
        url: '/customer/',
        method: 'PUT',
        body: { ...payload }
      })
    }),
    retrievePaymentMethod: builder.mutation({
      query: (payload) => ({
        url: '/customer/paymentMethods',
        method: 'POST',
        body: { ...payload }
      })
    }),
    removeMembership: builder.mutation({
      query: (payload) => ({
        url: '/customer/removeMembership',
        method: 'POST',
        body: { ...payload }
      })
    }),
    updateCommunicationInfo: builder.mutation({
      query: (payload) => ({
        url: '/customer/communication',
        method: 'POST',
        body: { ...payload }
      })
    }),
    updateCommunicationDetails: builder.mutation({
      query: (payload) => ({
        url: '/customer/address',
        method: 'PUT',
        body: { ...payload }
      })
    }),
    changeSubscription: builder.mutation({
      query: (payload) => ({
        url: '/customer/changeSubscription',
        method: 'POST',
        body: { ...payload }
      })
    }),
    cancelMembership: builder.mutation({
      query: (payload) => ({
        url: '/customer/cancel',
        method: 'POST',
        body: { ...payload }
      })
    }),
    updateAddress: builder.mutation({
      query: (payload) => ({
        url: '/customer/billing',
        method: 'POST',
        body: { ...payload }
      })
    }),
    requestNpCard: builder.mutation({
      query: (payload) => ({
        url: '/customer/npCard',
        method: 'POST',
        body: { ...payload }
      })
    })
  })
});

export const {
  useUpdateInfoMutation,
  useRetrievePaymentMethodMutation,
  useUpdateCommunicationInfoMutation,
  useChangeSubscriptionMutation,
  useRemoveMembershipMutation,
  useUpdateAddressMutation,
  useCancelMembershipMutation,
  useRequestNpCardMutation,
  useUpdateCommunicationDetailsMutation
} = portalApiSlice;
