import styled from 'styled-components';

export const Heading = styled.div.attrs({
  className: 'text-center filson-pro-bold'
})`
  font-size: 20px;
  color: blue;
`;

export const Title = styled.div.attrs({
  className: 'text-center filson-pro-bold'
})`
  font-size: 1.5rem;
  margin-bottom: 4vh;
`;

export const UserWrap = styled.div.attrs({
  className: 'd-flex flex-wrap justify-content-center'
})``;

export const UserName = styled.div.attrs({
  className: 'filson-pro-bold'
})`
  margin-right: 8px;
`;

export const CancelTxt = styled.p.attrs({
  className: 'text-center filson-pro-regular mt-3'
})`
  color: #0069b6;
  cursor: pointer;
  font: 15px;
  &:hover {
    color: rgb(0, 0, 255);
    text-decoration: underline;
  }
`;

export const Label = styled.span.attrs({
  className: 'text-center filson-pro-regular'
})`
  font-size: 13px;
`;

export const MemberNumber = styled.div``;
