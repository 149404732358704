import axios from 'axios';
import ls from 'utils/functions/localstore';

import { deepTransformKeys } from 'utils/functions/object';
import { toCamelCase, toSnakeCase } from 'utils/functions/text';

const axiosClient = axios.create();

const request = (method, service, url, opts) => {
  const payload = {
    baseURL: service,
    headers: {
      Authorization: `Bearer ${ls.get('token')}`
    },
    method,
    url,
    ...opts
  };
  return axiosClient
    .request(payload)
    .then((response) => {
      const { data } = response;
      // const { status, data } = response;

      return deepTransformKeys(data, toCamelCase);
    })
    .catch((error) => {
      const { config, response } = error;
      if (response) {
        const { status, data } = response;
        console.warn(
          `${config.method} call to "${config.url}" resulted in ${status} (${data.code})`
        );

        const throwable = { ...data, status };
        throw throwable;
      } else {
        console.log(`Error: ${error.message}`);
        const throwable = {
          status: -1,
          code: '-1',
          message: error.message,
          backtrace: error.stack
        };
        throw throwable;
      }
    });
};

const api = {
  get(service, url, data = {}) {
    return request('GET', service, url, {
      params: deepTransformKeys(data, toCamelCase)
    });
  },
  post(service, url, data = {}) {
    return request('POST', service, url, {
      data: deepTransformKeys(data, toCamelCase)
    });
  },
  put(service, url, data = {}) {
    return request('PUT', service, url, {
      data: deepTransformKeys(data, toCamelCase)
    });
  },
  delete(service, url, data = {}) {
    return request('DELETE', service, url, {
      params: deepTransformKeys(data, toSnakeCase)
    });
  }
};

export default api;

export const plugin = ({ baseUrl, method, path, params }) => api[method](baseUrl, path, params);
