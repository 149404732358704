import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getPersistConfig } from 'redux-deep-persist';
import rootReducer from './redux';
import { apiSlice } from './redux/rtkQuery/apiSlice';

// Create the persist config
const persistConfig = getPersistConfig({
  key: 'root',
  storage,
  debug: true,
  whitelist: ['auth.token'],
  rootReducer
});

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(apiSlice.middleware),
  devTools: true
});

// Create the persisted store
export const persistor = persistStore(store);
