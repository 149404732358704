import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsUserLoggedIn } from 'redux/auth';

const RequireAuth = () => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const location = useLocation();

  return isUserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/mymembership" state={{ from: location }} replace />
  );
};

export default RequireAuth;
