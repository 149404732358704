import {
  validCommonWealthRegex,
  validDvaPensionCardRegex,
  validGoldRepatriationRegex,
  validHealthCareRegex,
  validPensionerRegex,
  validSeniorRegex
} from 'utils/regex';
import { CHILD, CONCESSION, SPOUSE, USER_TYPE, CARD_TYPE } from 'utils/constants/variable';

export function getAge(dob) {
  let from = dob.split('/');
  let birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
  let cur = new Date();
  let diff = cur - birthdateTimeStamp;
  return Math.floor(diff / 31557600000);
}

export function IsValidHealthConcession(cardNumber) {
  if (validHealthCareRegex.test(cardNumber)) {
    return true;
  } else if (validPensionerRegex.test(cardNumber)) {
    return true;
  } else if (validGoldRepatriationRegex.test(cardNumber)) {
    return true;
  }
  return false;
}

export const IsValidSeniorConcession = (cardNumber) => validSeniorRegex.test(cardNumber);

export function validateWithAllCrn(cardNumber, cardType, crnMasked = false) {
  if (crnMasked) {
    return true;
  }
  switch (cardType) {
    case CARD_TYPE.HEALTH_CARE:
      return validHealthCareRegex.test(cardNumber);
    case CARD_TYPE.CONCESSION:
    case CARD_TYPE.PENSIONER:
      return validPensionerRegex.test(cardNumber);
    case CARD_TYPE.GOLD_REPATRIATION:
      return validGoldRepatriationRegex.test(cardNumber);
    case CARD_TYPE.SA_SENIORS:
      return validSeniorRegex.test(cardNumber);
    case CARD_TYPE.COMMONWEALTH_SENIORS:
      return validCommonWealthRegex.test(cardNumber);
    case CARD_TYPE.DVA_PENSION:
      return validDvaPensionCardRegex.test(cardNumber);
    default:
      return false;
  }
}

export function getConcessionAggregatePriority(cardType) {
  switch (cardType) {
    case CARD_TYPE.HEALTH_CARE:
    case CARD_TYPE.CONCESSION:
    case CARD_TYPE.PENSIONER:
    case CARD_TYPE.GOLD_REPATRIATION:
    case CARD_TYPE.COMMONWEALTH_SENIORS:
    case CARD_TYPE.DVA_PENSION:
      return CONCESSION.HEALTH;
    case CARD_TYPE.SA_SENIORS:
      return CONCESSION.SENIOR;
    default:
      return '';
  }
}

export const validateCrn = (cardNumber, type) => {
  let concessionFn = {
    [CONCESSION.HEALTH]: IsValidHealthConcession,
    [CONCESSION.SENIOR]: IsValidSeniorConcession
  };
  return concessionFn[type](cardNumber);
};

export const IsValidMedicare = (medicareNumber) => {
  if (!medicareNumber.match(/^[2-6]\d{3}\s?\d{5}\s?\d{1}$/)) return false;

  let isValid = false;

  if (medicareNumber && medicareNumber.length === 10) {
    const matches = medicareNumber.match(/^(\d{8})(\d)/);

    if (!matches) {
      return { invalid: true };
    }

    const base = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(base[i], 10) * weights[i];
    }

    isValid = sum % 10 === parseInt(checkDigit, 10);
  }

  return isValid;
};

export const checkConcessionPresent = (member) => {
  if ('coupon' in member) {
    let cardType = member.coupon?.cardType ?? '';
    return !isNullOrEmpty(cardType);
  }
  return false;
};

export const isSpouse = ({ relationshipType }) =>
  relationshipType === SPOUSE || relationshipType === USER_TYPE.SPOUSE_PARTNER;

export const isChild = (relationship) => relationship === CHILD;

export const isSpouseInMemberList = (memberList) =>
  memberList.filter((member) => isSpouse(member)).length > 0;

export const isLoggedInBySpouse = ({ relationshipType }) =>
  relationshipType === USER_TYPE.SPOUSE_PARTNER;

export const isNullOrEmpty = (val) => val == null || val.trim() === '';

export const getPrimaryMember = (users) =>
  users.find((user) => user.relationshipType === USER_TYPE.PRIMARY);
export const getNonPrimaryMember = (users) =>
  users.filter((user) => user.relationshipType !== USER_TYPE.PRIMARY);
