import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { ButtonLoader } from 'components/Loader';
import MemberList from 'components/MemberList';

import { closeModel } from 'redux/model';
import { showSnackbar } from 'redux/snackbar';
import { updateMembership } from 'redux/portal';
import { loadPolicy, selectLoggedInUserId } from 'redux/auth';
import { useRemoveMembershipMutation } from 'redux/rtkQuery/portalApiSlice';

import { getActiveUser, getUserTypeFromToken } from 'utils/functions';
import { findUserPolicy } from 'utils/functions/rules';

import { active, disable, dropDown, ModelBtn } from 'styles';

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
  text-align: center;
`;

export const Label = styled.span.attrs({
  className: 'text-center filson-pro-regular'
})`
  font-size: 13px;
`;

const SelfRemove = () => {
  const { dropdown } = useSelector((state) => state.chargebee);
  const { token } = useSelector((state) => state.auth);
  const { membership } = useSelector((state) => state.portal);
  const loggedInUserId = useSelector(selectLoggedInUserId);
  const loggedInUser = getActiveUser(membership.users, loggedInUserId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [removeMembership, { isLoading }] = useRemoveMembershipMutation();

  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const handleCancel = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      let payload = {
        reasonCode: reasonCode.value
      };
      removeMembership(payload)
        .unwrap()
        .then((response) => {
          dispatch(updateMembership(response));
          dispatch(loadPolicy(findUserPolicy(response, getUserTypeFromToken(response, token))));
          dispatch(closeModel());
        })
        .then(() => navigate('/mymembership/confirmation', { state: 1 }))
        .catch((err) => {
          console.log(err);
          dispatch(showSnackbar({ message: err?.data?.message, severity: 'error' }));
        });
    }
  };

  const handleClose = () => dispatch(closeModel());

  return (
    <>
      <Modal.Header closeButton>
        <ModelTitle>Remove Membership</ModelTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <p className="text-center filson-pro-bold font15px">
            Are you sure you would like to be removed from this membership?
          </p>
          <div className="text-center filson-pro-regular font13px red">
            This will remove you from the Family Membership and will cancel all your Member Benefits
            immediately.
          </div>
          <br />
          <p className="text-center filson-pro-regular font13px">
            No refunds in Membership premiums are applicable as per the Terms and Conditions of the
            Membership.
          </p>

          <MemberList users={[loggedInUser]} />

          <div className="container font13px" style={{ width: '50%' }}>
            <div className="text-center">
              <Label htmlFor="reasonCode">Please select a reason:</Label>
            </div>
            <Select
              styles={dropDown('')}
              options={dropdown?.reasonCodeForSelfRemove}
              value={reasonCode}
              isSearchable={false}
              onChange={handleReasonCodeChange}
            />
            <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
          </div>
          <div className="text-center mb-3">
            <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClose}>
              Cancel
            </ModelBtn>
            <ModelBtn
              onClick={handleCancel}
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : 'Confirm'}
            </ModelBtn>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default SelfRemove;
