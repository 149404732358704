import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setCredentials, authReset } from 'redux/auth';
import { membershipReset } from 'redux/membership';
import { portalReset } from 'redux/portal';
import { detailsReset } from 'redux/details';
import { estimateReset } from 'redux/estimate';
import { modelReset } from 'redux/model';

import { NP_ENGINE } from 'settings/dev';

const baseQuery = fetchBaseQuery({
  baseUrl: NP_ENGINE,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 403) {
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
    const accessToken = getAccessToken(refreshResult);
    if (accessToken) {
      api.dispatch(setCredentials(accessToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      resetReduxState(api);
    }
  }
  return result;
};

const getAccessToken = (result) => {
  let headers = {};
  result?.meta?.response?.headers.forEach((value, key) => {
    headers[key] = value;
  });
  return headers.authorization;
};

const resetReduxState = (api) => {
  api.dispatch(authReset());
  api.dispatch(portalReset());
  api.dispatch(membershipReset());
  api.dispatch(detailsReset());
  api.dispatch(estimateReset());
  api.dispatch(modelReset());
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  // eslint-disable-next-line no-unused-vars
  endpoints: (builders) => ({})
});
