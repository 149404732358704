import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { closeModel } from 'redux/model';
import { loginPrePopulate } from 'redux/membership';

import { ModelHeader, ModelTitle, ModelBody } from '../style';
import { ModelBtn } from 'styles';

export default function MembershipNotFound() {
  const { data } = useSelector((state) => state.model);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUndefined = (val) => (val === undefined ? '' : val);

  const handleClick = (ev) => {
    ev.preventDefault();
    let { lastName, dob, email, phone } = data;
    dispatch(
      loginPrePopulate({
        lastName: isUndefined(lastName),
        dob: isUndefined(dob).length > 0 ? dob : '',
        email: isUndefined(email),
        mobilePhone: isUndefined(phone)
      })
    );
    handleClose();
    navigate('/store/hub/new');
  };

  const handleClose = () => dispatch(closeModel());
  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Member not found</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <div className="text-center mb-5">
          <p className="font12px mt-5">Member not found.</p>
          <p className="font12px mt-1"> Would you like to join this member?</p>
          <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClick}>
            Yes
          </ModelBtn>
          <ModelBtn onClick={handleClose}>Try Search Again</ModelBtn>
        </div>
      </ModelBody>
    </>
  );
}
