import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';

import { ButtonLoader } from 'components/Loader';

import useAddress from 'utils/hooks/useAddress';

import { showSnackbar } from 'redux/snackbar';
import { closeModel } from 'redux/model';
import { updateMembership } from 'redux/portal';
import { selectLoggedInUserId } from 'redux/auth';
import { updateContactDetails } from 'redux/details';
import { useUpdateAddressMutation } from 'redux/rtkQuery/portalApiSlice';

import { precheck } from './validator';

import { findLoggedInUser, formatPhoneNo } from 'utils/functions';
import { STATES } from 'utils/constants/dropdown';
import { HOME_PHONE, MOBILE_PHONE } from 'utils/constants/variable';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { active, Btn, disable, dropDown, Label, LabelNR } from 'styles';
import { Container, Row, Col } from 'styles/bootstrap';
import { ModelBody, ModelHeader, ModelTitle, CheckboxContent } from '../style';

const AddressUpdate = () => {
  const { googleMapApiKey } = useSelector((state) => state.chargebee);
  const { membership } = useSelector((state) => state.portal);
  const { mobilePhone, homePhone } = useSelector((state) => state.details);
  const loggedInUserId = useSelector(selectLoggedInUserId);
  let loggedInMember = findLoggedInUser(membership.users, loggedInUserId);

  const [updateAddress, { isLoading }] = useUpdateAddressMutation();
  const {
    address,
    addressErrors,
    handleAddressAutoComplete,
    handleAddressChange,
    handleAddressDropDown,
    validateAddress
  } = useAddress();

  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: loggedInMember.email,
    mobilePhone,
    homePhone
  });

  const [isEmailNotMandatory, setIsEmailMandatory] = useState(loggedInMember.email.length === 0);

  const [errors, setErrors] = useState({
    street: '',
    suburb: '',
    postcode: '',
    email: '',
    mobilePhone: '',
    homePhone: ''
  });

  useEffect(
    () =>
      setErrors({
        street: '',
        suburb: '',
        postcode: '',
        email: '',
        mobilePhone: '',
        homePhone: ''
      }),
    [address, data]
  );

  const handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    if (name === HOME_PHONE || name === MOBILE_PHONE) {
      value = formatPhoneNo(value);
    }
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleEmailCheckbox = (ev) => {
    setIsEmailMandatory(ev.target.checked);
    setData({ ...data, email: '' });
    setErrors({ ...errors, email: '', mobilePhone: '' });
  };

  const getSelectedOption = (optionList, value) => {
    if (optionList !== undefined) {
      let optionSelected = optionList.find((option) => option.value === value);
      return optionSelected === undefined ? null : optionSelected;
    }
    return null;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (
      precheck(data, validateAddress(address.addressLine1), errors, setErrors, isEmailNotMandatory)
    ) {
      const payload = {
        ...address,
        email: data.email,
        mobilePhone: data.mobilePhone,
        homePhone: data.homePhone
      };
      updateAddress(payload)
        .unwrap()
        .then(() => {
          postAction();
          dispatch(
            showSnackbar({ severity: 'success', message: 'Contact details updated successfully' })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(showSnackbar({ severity: 'error', message: err?.data?.message }));
        })
        .finally(() => dispatch(closeModel()));
    }
  };

  const postAction = () => {
    let membershipClone = JSON.parse(JSON.stringify(membership));

    membershipClone.users = membershipClone.users.map((user) => {
      if (user.memberNumber === loggedInUserId) {
        return {
          ...user,
          email: data.email,
          mobilePhone: data.mobilePhone,
          homePhone: data.homePhone
        };
      }
      return user;
    });

    membershipClone.billingAddressRequest = address;
    dispatch(updateMembership(membershipClone));

    let { mobilePhone, homePhone } = data;
    dispatch(
      updateContactDetails({
        mobilePhone,
        homePhone,
        address
      })
    );
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Contact Details</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <Container>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-wrap">
                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLookup">Address Lookup </LabelNR>
                  <Autocomplete
                    className="form-control"
                    apiKey={googleMapApiKey}
                    placeholder={''}
                    onPlaceSelected={(s) => handleAddressAutoComplete(s)}
                    options={{
                      types: ['geocode', 'establishment'],
                      fields: [
                        'name',
                        'geometry',
                        'place_id',
                        'formatted_address',
                        'address_components'
                      ],
                      componentRestrictions: { country: 'aus' }
                    }}
                  />
                </div>

                <div className="form-member-group-lg">
                  <Label htmlFor="addressLine1">Address Line 1 </Label>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine1"
                    value={address.addressLine1}
                    onChange={handleAddressChange}
                    maxLength="150"
                    autoComplete="off"
                  />
                  <span className="error">{addressErrors.addressLine1}</span>
                </div>

                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLine2">Address Line 2 </LabelNR>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine2"
                    value={address.addressLine2}
                    onChange={handleAddressChange}
                    maxLength="150"
                    autoComplete="off"
                  />
                </div>

                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLine3">Address Line 3 </LabelNR>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine3"
                    value={address.addressLine3}
                    onChange={handleAddressChange}
                    maxLength="150"
                    autoComplete="off"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Label htmlFor="suburb">Suburb</Label>
              <input
                type="text"
                className="form-control"
                name="suburb"
                value={address.suburb}
                onChange={handleAddressChange}
                maxLength="50"
                autoComplete="off"
              />
              <span className="error">{addressErrors.suburb}</span>
            </Col>
            <Col md={6}>
              <Label htmlFor="postcode">Postcode</Label>
              <input
                type="text"
                className="form-control"
                name="postcode"
                value={address.postcode}
                onChange={handleAddressChange}
                maxLength="4"
                autoComplete="off"
              />
              <span className="error">{addressErrors.postcode}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Select
                className="mt-3_5"
                styles={dropDown(false)}
                options={STATES}
                isSearchable={false}
                value={getSelectedOption(STATES, address.state)}
                onChange={handleAddressDropDown}
                placeholder={'*Select State/Territory'}
              />
              <span className="error">{addressErrors.state}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <LabelNR className={isEmailNotMandatory ? '' : 'required'} htmlFor="email">
                Email Address
              </LabelNR>
              <input
                type="text"
                className="form-control"
                name="email"
                value={data.email}
                onChange={handleChange}
                maxLength="70"
                disabled={isEmailNotMandatory}
                autoComplete="off"
              />
              <span className="error">{errors.email}</span>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isEmailNotMandatory}
                      onChange={handleEmailCheckbox}
                    />
                  }
                  label={<CheckboxContent>Customer does not have an email address</CheckboxContent>}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <LabelNR className={isEmailNotMandatory ? 'required' : ''} htmlFor="mobilePhone">
                Mobile Phone (04x xxx xxxx){' '}
              </LabelNR>
              <input
                type="text"
                className="form-control"
                name="mobilePhone"
                value={data.mobilePhone}
                onChange={handleChange}
                maxLength="12"
                autoComplete="off"
              />
              <span className="error">{errors.mobilePhone}</span>
            </Col>
            <Col md={6}>
              <LabelNR htmlFor="homePhone">Home Phone (0xx xxx xxxx)</LabelNR>
              <input
                type="text"
                className="form-control"
                name="homePhone"
                value={data.homePhone}
                onChange={handleChange}
                maxLength="12"
                autoComplete="off"
              />
              <span className="error">{errors.homePhone}</span>
            </Col>
          </Row>
          <div className="text-center mb-3 mt-3">
            <Btn
              onClick={handleSubmit}
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : 'Save'}
            </Btn>
          </div>
        </Container>
      </ModelBody>
    </>
  );
};

export default AddressUpdate;
