import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { USER_TYPE } from 'utils/constants/variable';

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
  text-align: center;
`;

export const Label = styled.span.attrs({
  className: 'text-center filson-pro-regular'
})`
  font-size: 13px;
`;

const MembershipScheduled = () => {
  const { data } = useSelector((state) => state.model);
  const message =
    data.relationshipType === USER_TYPE.PRIMARY
      ? 'Your membership has a renewal request in progress and is currently being updated, to make any changes or to cancel this request, please contact Member Services on 1300 667 676.'
      : 'This membership has a renewal request in progress and is currently being updated, to make any changes please contact Member Services on 1300 667 676.';
  return (
    <>
      <Modal.Header closeButton>
        <ModelTitle>Renewal In Progress</ModelTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <div className="text-center filson-pro-regular font13px">{message}</div>
          <br />
        </div>
      </Modal.Body>
    </>
  );
};

export default MembershipScheduled;
