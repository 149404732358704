import { createSlice } from '@reduxjs/toolkit';
import { NP_ENGINE } from 'settings/dev';
import api from 'utils/functions/api';

const initialState = {
  storeOptions: [],
  selectedStore: null,
  singleItemPrices: [],
  familyItemPrices: [],
  couponEstimate: '',
  amountWithoutDiscount: 0,
  discount: 0,
  price: 0,
  isAllowed: true,
  memberships: []
};

export const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    storeInit: (state, action) => {
      state.storeOptions = action.payload;
    },
    storeUpdate: (state, action) => {
      state.selectedStore = action.payload;
    },
    membershipInit: (state, action) => {
      state.memberships = action.payload;
    },
    planInit: (state, action) => {
      let { single, family, couponEstimate } = action.payload;
      state.singleItemPrices = single;
      state.familyItemPrices = family;
      state.couponEstimate = couponEstimate;
      state.isAllowed = false;
    },
    initEstimate: (state, action) => {
      let { price, amountWithoutDiscount, discount } = action.payload;
      state.price = price;
      state.amountWithoutDiscount = amountWithoutDiscount;
      state.discount = discount;
    },
    updateMemberships: (state, action) => {
      state.memberships = action.payload;
    },
    storeDisableNpCard: (state, action) => {
      const { memberNumber, index } = action.payload;
      let clone = [...state.memberships];
      let membership = clone[index];
      membership.users = membership.users.map((user) => {
        if (user.memberNumber === memberNumber) {
          user.npCard = false;
        }
        return user;
      });
      clone[index] = membership;
      state.memberships = clone;
    },
    storeReset: () => {
      return initialState;
    }
  }
});

export const {
  storeInit,
  planInit,
  membershipInit,
  storeUpdate,
  storeReset,
  initEstimate,
  updateMemberships,
  storeDisableNpCard
} = store.actions;

export default store.reducer;

export const getStoreDetails = () => (dispatch) =>
  api.get(NP_ENGINE, `/store/config/stores`).then((response) => {
    let sortedList = response.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    dispatch(storeInit(sortedList));
    return response;
  });

export const searchMember = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/store/`, payload).then((response) => {
    dispatch(membershipInit(response));
    return response;
  });

export const fetchPaymentPlan = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/common/items`, payload).then((response) => {
    dispatch(planInit(response));
    return response;
  });

export const getEstimate = (payload) => (dispatch) =>
  api.post(NP_ENGINE, '/items/estimate', payload).then((response) => {
    dispatch(initEstimate(response));
    return response;
  });

export const processPayNow = (payload) => () =>
  api.post(NP_ENGINE, '/store/changeSubscription', payload).then((response) => {
    return response;
  });

export const processUpgrade = (payload) => () =>
  api.post(NP_ENGINE, `/store/changeSubscription`, payload).then((response) => {
    return response;
  });

export const updateUserDetails = (payload) => () =>
  api.put(NP_ENGINE, '/store/', payload).then((response) => {
    return response;
  });

export const updateBillingAddress = (payload) => () =>
  api.post(NP_ENGINE, '/store/address', payload).then((response) => {
    return response;
  });

export const storeRequestNpCard = (payload) => () =>
  api.post(NP_ENGINE, `/store/npCard`, payload).then((response) => {
    return response;
  });
