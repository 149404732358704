import { validEmailRegex, validHomePhoneRegex, validMobilePhoneRegex } from 'utils/regex';

export const precheck = (data, validateAddress, errors, setErrors, isEmailNotMandatory) => {
  let errorClone = { ...errors };
  let isValid = validateAddress;

  if (isEmailNotMandatory) {
    if (data.mobilePhone.trim().length === 0) {
      errorClone.mobilePhone = 'This field is required.';
      isValid = false;
    } else {
      errorClone.mobilePhone = validMobilePhoneRegex.test(data.mobilePhone.replaceAll(/\s/g, ''))
        ? ''
        : 'Please enter a valid value';
      errorClone.mobilePhone.length > 0 && (isValid = false);
    }
  } else {
    if (data.email.trim().length === 0) {
      errorClone.email = 'This field is required.';
      isValid = false;
    } else {
      errorClone.email = validEmailRegex.test(data.email) ? '' : 'Please enter a valid value';
      errorClone.email.length > 0 && (isValid = false);
    }
  }

  if (
    data.homePhone.length > 0 &&
    !validHomePhoneRegex.test(data.homePhone.replaceAll(/\s/g, ''))
  ) {
    errorClone.homePhone = 'Please enter a valid value';
    isValid = false;
  }

  setErrors(errorClone);
  return isValid;
};
