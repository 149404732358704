import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NP_ENGINE } from 'settings/dev';
import axios from 'axios';
import { convertToPlanDropdown } from 'utils/functions';
import api from 'utils/functions/api';

const initialState = {
  plans: {},
  planDropdown: [],
  concessionDiscount: '',
  membershipPlan: '',
  paymentPlan: '',
  tempMembershipPlan: '',
  tempPaymentPlan: '',
  planSwitched: false,
  isProcessing: false,
  memberNumber: '',
  customerId: '',
  subscriptionId: '',
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  mobilePhone: '',
  opticalCustomer: false,
  postcode: '',
  termsAndCondition: false,
  totalCost: '',
  emailNotMandatory: false,
  isPaymentPlanDisabled: true,
  isYourDetailsDisabled: true,
  isAllowed: true,
  familyDetails: false,
  maxDependent: 0,
  relationshipType: [],
  selectedStore: undefined,
  storeDisabled: false
};

export const membership = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    membershipUpdate: (state, action) => {
      let {
        membershipPlan,
        paymentPlan,
        itemList,
        memberNumber,
        customerId,
        subscriptionId,
        title,
        firstName,
        lastName,
        dob,
        email,
        mobilePhone,
        postcode,
        opticalCustomer,
        termsAndCondition,
        totalCost,
        emailNotMandatory,
        selectedStore,
        storeDisabled
      } = action.payload;

      state.membershipPlan = membershipPlan;
      state.paymentPlan = paymentPlan;
      state.tempMembershipPlan = membershipPlan;
      state.tempPaymentPlan = paymentPlan;
      state.planSwitched = false;
      state.itemList = itemList;
      state.title = title;
      state.firstName = firstName;
      state.lastName = lastName;
      state.dob = dob;
      state.email = email;
      state.mobilePhone = mobilePhone;
      state.postcode = postcode;
      state.opticalCustomer = opticalCustomer;
      state.termsAndCondition = termsAndCondition;
      state.totalCost = totalCost;
      state.emailNotMandatory = emailNotMandatory;
      state.memberNumber = memberNumber;
      state.customerId = customerId;
      state.subscriptionId = subscriptionId;
      state.pageDisabled = false;
      state.selectedStore = selectedStore;
      state.storeDisabled = storeDisabled;
    },
    updatePlanConfig: (state, action) => {
      let { familyDetails, maxDependent, relationshipType } = action.payload;
      state.familyDetails = familyDetails;
      state.maxDependent = maxDependent;
      state.relationshipType = relationshipType;
    },
    resetPlanSwitch: (state) => {
      state.planSwitched = false;
    },
    updatedEmailAddress: (state, action) => {
      state.email = action.payload;
    },
    updatedOpticalCustomer: (state, action) => {
      state.opticalCustomer = action.payload;
    },
    displayPaymentPlanSection: (state) => {
      state.isPaymentPlanDisabled = false;
    },
    displayYourDetailSection: (state) => {
      state.isYourDetailsDisabled = false;
    },
    temporaryPlanUpdate: (state, action) => {
      let { membershipPlan, paymentPlan } = action.payload;
      state.tempMembershipPlan = membershipPlan;
      state.tempPaymentPlan = paymentPlan;
      state.planSwitched = true;
    },
    loginPrePopulate: (state, action) => {
      let { lastName, dob, email, mobilePhone } = action.payload;
      state.lastName = lastName;
      state.dob = dob;
      state.email = email;
      state.mobilePhone = mobilePhone;
    },
    membershipReset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanList.pending, (state) => {
      state.isProcessing = true;
    });
    builder.addCase(fetchPlanList.fulfilled, (state, action) => {
      let { plans } = action.payload;
      state.plans = plans;
      state.planDropdown = convertToPlanDropdown(plans);
      state.isProcessing = false;
      state.isAllowed = false;
    });
    builder.addCase(fetchPlanList.rejected, (state) => {
      state.isProcessing = false;
    });
  }
});

export const fetchPlanList = createAsyncThunk(
  'membership/getPlanItems',
  async (membership, thunkAPI) => {
    try {
      const resp = await axios(`${NP_ENGINE}/common/items`);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const cancelMembership = (payload) => () =>
  api.post(NP_ENGINE, `/store/cancel`, payload).then((response) => {
    return response;
  });

// Action creators are generated for each case reducer function
export const {
  membershipUpdate,
  resetPlanSwitch,
  temporaryPlanUpdate,
  displayPaymentPlanSection,
  displayYourDetailSection,
  membershipReset,
  loginPrePopulate,
  updatePlanConfig,
  updatedEmailAddress,
  updatedOpticalCustomer
} = membership.actions;

export default membership.reducer;
