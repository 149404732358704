import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import EmptyBlock from 'components/EmptyBlock';
import { ButtonLoader } from 'components/Loader';

import { cancelMembership } from 'redux/membership';
import { closeModel } from 'redux/model';
import { showSnackbar } from 'redux/snackbar';

import { ellipsis, toTitleCase } from 'utils/functions/text';
import { PRE_64, RELATIONSHIP, BOOPA_FLAG } from 'utils/constants/variable';
import { checkIsExpiredMembership, getPrimaryMember } from 'utils/functions';

import { Heading, Title, MemberNumber, UserName, UserWrap } from './style';
import { ModelTitle } from '../style';
import { CancelTxt, Label } from '../SwitchPlan/style';
import { active, disable, dropDown, ModelBtn } from 'styles';
import { useNavigate } from 'react-router-dom';

export default function StoreMembershipDetails() {
  const { dropdown } = useSelector((state) => state.chargebee);
  const { memberships } = useSelector((state) => state.store);
  const { data } = useSelector((state) => state.model);
  const { membershipIndex, activeUser, allowMembershipCancel = false } = data;
  const { pre64, boopaFlag } = activeUser;
  const { users, config, item } = memberships[membershipIndex];

  const [cancel, setCancel] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { validTillDate, nextPaymentDueDate, isScheduledChanges, membershipName, status } = config;

  const membershipPlanName = `${membershipName} ${pre64 ? `- ${PRE_64}` : ''} ${
    boopaFlag ? `- ${BOOPA_FLAG}` : ''
  }`.trim();

  const Role = {
    PR: RELATIONSHIP.PRIMARY,
    SP: RELATIONSHIP.SPOUSE_PARTNER,
    CD: RELATIONSHIP.CHILD_DEPENDANT
  };

  const getPaymentPlan = (paymentPlan) => {
    return `${toTitleCase(paymentPlan)} over 12 months subscription`;
  };

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const isCancelledMembership = checkIsExpiredMembership(status);

  const handleClose = () => dispatch(closeModel());

  const handleCancel = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      let primaryUser = getPrimaryMember({ users });
      let payload = {
        user: {
          memberNumber: primaryUser.memberNumber
        },
        reasonCode: reasonCode.value
      };
      setIsProcessing(true);
      dispatch(cancelMembership(payload))
        .then(() => {
          setIsProcessing(true);
          dispatch(closeModel());
        })
        .then(() => navigate('/store/hub/confirmation'))
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          dispatch(showSnackbar({ message: err.message, severity: 'error' }));
        });
    }
  };

  const showCancelMembership = () => allowMembershipCancel && cancel;
  const showCancelLink = () => allowMembershipCancel;

  if (showCancelMembership()) {
    return (
      <>
        <Modal.Header closeButton>
          <ModelTitle>Cancel Membership</ModelTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <p className="text-center filson-pro-regular font13px">
              Are you sure you would like to cancel this Membership ?
            </p>
            <p className="text-center filson-pro-regular font13px red">
              if you would like to continue your membership, please close this window <br /> or
              select the the the cancel option below.
            </p>
            <br />
            <p className="text-center filson-pro-regular font13px">
              Please note: Any unused portion of your membership will not be <br /> refunded and all
              benefits are cancelled immediately.
            </p>

            <div className="container font13px" style={{ width: '30%' }}>
              <div className="text-center">
                <Label htmlFor="reasonCode">Please select a reason:</Label>
              </div>
              <Select
                styles={dropDown('')}
                options={dropdown.reasonCodeForCancel}
                value={reasonCode}
                isSearchable={false}
                onChange={handleReasonCodeChange}
              />
              <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
            </div>

            <div className="text-center mb-3">
              <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClose}>
                Cancel
              </ModelBtn>
              <ModelBtn
                onClick={handleCancel}
                style={isProcessing ? disable : active}
                isProcessing={isProcessing}
                disabled={isProcessing}>
                {isProcessing ? <ButtonLoader /> : 'Confirm'}
              </ModelBtn>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  } else {
    return (
      <>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Title>Membership Details</Title>
            <Heading>
              <b>{membershipPlanName}</b>
            </Heading>

            <div className="text-center filson-pro-bold mb-3">
              <b className="d-block">{getPaymentPlan(item.name)}</b>

              {isScheduledChanges && (
                <>
                  {/* <Heading className="mt-3">
                  <b>{`Membership renewed to ${
                    scheduledItem.concession ? 'Concession -' : ''
                  } ${toTitleCase(scheduledItem.planName)}`}</b>
                </Heading>
                <b className="d-block">{getPaymentPlan(scheduledItem.name)}</b> */}
                </>
              )}

              <b className="d-block">{`Membership Benefit until ${validTillDate}`}</b>

              {nextPaymentDueDate && (
                <b className="d-block">{`Next payment due: ${nextPaymentDueDate}`}</b>
              )}
            </div>

            {users.map(({ firstName, lastName, memberNumber, relationshipType }, index) => (
              <UserWrap key={index}>
                <UserName className="text-align-center">
                  {`${ellipsis(`${firstName} ${lastName}`)}`}
                </UserName>

                <MemberNumber className="text-align-center">
                  {`Member #${memberNumber} (${Role[relationshipType]})`}
                </MemberNumber>
              </UserWrap>
            ))}

            {isCancelledMembership ? (
              <EmptyBlock />
            ) : (
              showCancelLink() && (
                <CancelTxt onClick={() => setCancel(true)}>Cancel Membership</CancelTxt>
              )
            )}

            <br />

            <p className="text-center filson-pro-regular font12px mb-5">
              If you have any questions relating to your Membership,
              <br /> please contact Member Services on 1300 667 676.
            </p>
          </div>
        </Modal.Body>
      </>
    );
  }
}
