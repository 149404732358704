import { ModelHeader, ModelBody } from '../style';

export default function ComingSoon() {
  return (
    <>
      <ModelHeader closeButton></ModelHeader>
      <ModelBody>
        <div className="text-center mb-5">
          <p className="mt-5">This functionality is coming soon.</p>
        </div>
      </ModelBody>
    </>
  );
}
