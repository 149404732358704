import { createSlice } from '@reduxjs/toolkit';
import api from 'utils/functions/api';
import { NP_ENGINE } from 'settings/dev';

const initialState = {
  isCbInitiated: false,
  cbInstance: '',
  googleMapApiKey: '',
  plans_config: '',
  age: '',
  dropdown: '',
  paymentMethod: '',
  concessionPriority: {},
  url: {
    shopNow: '',
    contactUs: '',
    wordpressHome: ''
  }
};

export const chargebee = createSlice({
  name: 'chargebee',
  initialState,
  reducers: {
    loadChargebee: (state, action) => {
      let { cbSite, cbPublishableKey, googleMapApiKey, vPolicy, urlObject } = action.payload;
      state.cbInstance = window.Chargebee.init({
        site: cbSite,
        publishableKey: cbPublishableKey,
        isItemsModel: true
      });
      state.googleMapApiKey = googleMapApiKey;
      state.isChargebeeInitiated = true;
      state.url = urlObject;

      const policy = JSON.parse(vPolicy);
      state.dropdown = policy.dropDown;
      state.age = policy.age;
      state.plans_config = policy.plans;
      state.concessionPriority = policy.concessionPriority;
    }
  }
});

export const { loadChargebee } = chargebee.actions;

export default chargebee.reducer;

export const initChargebee = () => (dispatch) =>
  api.get(NP_ENGINE, '/store/config/cb').then((response) => {
    dispatch(loadChargebee(response));
    return response;
  });
