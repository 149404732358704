import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { ButtonLoader } from 'components/Loader';

import { closeModel } from 'redux/model';
import { showSnackbar } from 'redux/snackbar';
import { updateMembership } from 'redux/portal';
import { loadPolicy } from 'redux/auth';
import { useCancelMembershipMutation } from 'redux/rtkQuery/portalApiSlice';

import { findUserPolicy } from 'utils/functions/rules';
import { deepClone } from 'utils/functions/object';
import { getUserTypeFromToken } from 'utils/functions';
import { PORTAL_MEMBERSHIP_STATUS } from 'utils/constants/variable';

import { active, disable, dropDown, ModelBtn } from 'styles';

export const ModelTitle = styled(Modal.Title).attrs({
  className: 'w-100 filson-pro-bold'
})`
  font-size: 100%;
  text-align: center;
`;

export const Label = styled.span.attrs({
  className: 'text-center filson-pro-regular'
})`
  font-size: 13px;
`;

const CancelMembership = () => {
  const { dropdown } = useSelector((state) => state.chargebee);
  const { membership } = useSelector((state) => state.portal);
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cancelMembership, { isLoading }] = useCancelMembershipMutation();

  const [reasonCode, setReasonCode] = useState('');
  const [reasonCodeError, setReasonCodeError] = useState('');

  const handleReasonCodeChange = (e) => {
    setReasonCode(e);
    setReasonCodeError('');
  };

  const getClonedMembership = () => {
    let membershipClone = deepClone(membership);
    membershipClone.config['status'] = PORTAL_MEMBERSHIP_STATUS.EXPIRED;
    membershipClone.config['nextPaymentDueDate'] = '';
    membershipClone.config['invoiceId'] = '';
    membershipClone.config['isEligibleForRenewal'] = true;
    return membershipClone;
  };

  const handleCancel = () => {
    if (reasonCode === '') {
      setReasonCodeError('You must select a reason');
    } else {
      let payload = {
        reasonCode: reasonCode.value
      };
      cancelMembership(payload)
        .unwrap()
        .then(async () => {
          let membershipClone = await getClonedMembership();
          await dispatch(updateMembership(membershipClone));
          await dispatch(
            loadPolicy(
              findUserPolicy(membershipClone, getUserTypeFromToken(membershipClone, token))
            )
          );
          await dispatch(closeModel());
        })
        .then(() => navigate('/mymembership/confirmation', { state: 0 }))
        .catch((err) => {
          console.log(err);
          dispatch(showSnackbar({ message: err?.data?.message, severity: 'error' }));
        });
    }
  };

  const handleClose = () => dispatch(closeModel());

  return (
    <>
      <Modal.Header closeButton>
        <ModelTitle>Cancel Membership</ModelTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-5">
          <p className="text-center filson-pro-bold font15px">
            Are you sure you would like to cancel this Membership ?
          </p>
          <div className="text-center filson-pro-regular font13px" style={{ color: '#FF0000' }}>
            if you would like to continue your membership, please close this window <br /> or select
            the the the cancel option below.
          </div>
          <br />
          <p className="text-center filson-pro-regular font13px">
            Please note: Any unused portion of your membership will not be <br /> refunded and all
            benefits are cancelled immediately.
          </p>
          <div className="container font13px" style={{ width: '50%' }}>
            <div className="text-center">
              <Label htmlFor="reasonCode">Please select a reason:</Label>
            </div>
            <Select
              styles={dropDown('')}
              options={dropdown?.reasonCodeForCancel}
              value={reasonCode}
              isSearchable={false}
              onChange={handleReasonCodeChange}
            />
            <p className="error text-center filson-pro-regular font13px">{reasonCodeError}</p>
          </div>
          <div className="text-center mb-3">
            <ModelBtn style={{ marginRight: '2vw' }} onClick={handleClose}>
              Cancel
            </ModelBtn>
            <ModelBtn
              onClick={handleCancel}
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : 'Confirm'}
            </ModelBtn>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default CancelMembership;
