import { combineReducers } from 'redux';
import auth from './auth';
import chargebee from './chargebee';
import form from './form';
import snackbar from './snackbar';
import membership from './membership';
import details from './details';
import payment from './payment';
import estimate from './estimate';
import payNow from './payNow';
import portal from './portal';
import store from './store';
import renew from './store/renew';
import thankYou from './store/thankYou';
import model from './model';
import { apiSlice } from './rtkQuery/apiSlice';

const rootReducer = combineReducers({
  auth,
  chargebee,
  form,
  snackbar,
  membership,
  details,
  payment,
  payNow,
  portal,
  store,
  renew,
  estimate,
  thankYou,
  model,
  [apiSlice?.reducerPath]: apiSlice?.reducer
});

export default rootReducer;
