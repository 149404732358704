import React from 'react';
import { Route } from 'react-router-dom';
import { MembershipSearch, PrivateRoutes } from 'routes/wrapper/privateRoute';
import lazyWithFallback from 'utils/lazyWithFallback';

const MembershipHub = lazyWithFallback(() => import('pages/store/membershipHub'));
const CreateMembershipFromStore = lazyWithFallback(() =>
  import('pages/store/membershipHub/createMembership')
);
const ListMembership = lazyWithFallback(() => import('pages/store/membershipHub/listMembership'));
const RenewMembership = lazyWithFallback(() => import('pages/store/membershipHub/renewMembership'));
const UpdateMembership = lazyWithFallback(() =>
  import('pages/store/membershipHub/updateMembership')
);
const PayNow = lazyWithFallback(() => import('pages/store/membershipHub/payNow'));
const Payment = lazyWithFallback(() => import('pages/store/membershipHub/payment'));
const ThankYou = lazyWithFallback(() => import('pages/store/membershipHub/thankYou'));
const Confirmation = lazyWithFallback(() => import('pages/store/membershipHub/confirmation'));

export const StoreRoutes = () => (
  <Route element={<PrivateRoutes />}>
    {/*store route*/}
    <Route exact path="/store/hub" element={<MembershipHub />} />
    <Route exact path="/store/hub/new" element={<CreateMembershipFromStore />} />
    <Route element={<MembershipSearch />}>
      <Route exact path="/store/hub/list" element={<ListMembership />} />
      <Route exact path="/store/hub/renew" element={<RenewMembership />} />
      <Route exact path="/store/hub/update" element={<UpdateMembership />} />
      <Route exact path="/store/hub/payNow" element={<PayNow />} />
      <Route exact path="/store/hub/payment" element={<Payment />} />
      <Route exact path="/store/hub/thankYou" element={<ThankYou />} />
      <Route exact path="/store/hub/confirmation" element={<Confirmation />} />
    </Route>
  </Route>
);
