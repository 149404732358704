import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';

import { ButtonLoader } from 'components/Loader';

import useAddress from 'utils/hooks/useAddress';
import { showSnackbar } from 'redux/snackbar';
import { closeModel } from 'redux/model';
import { updateContactDetails } from 'redux/details';

import { precheck } from './validator';

import { formatPhoneNo, getActiveUser } from 'utils/functions';
import { STATES } from 'utils/constants/dropdown';
import { HOME_PHONE, MOBILE_PHONE } from 'utils/constants/variable';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { active, Btn, disable, dropDown, Label, LabelNR } from 'styles';
import { Container, Row, Col } from 'styles/bootstrap';
import { CheckboxContent, ModelBody, ModelHeader, ModelTitle } from '../style';
import { updateBillingAddress, updateMemberships } from 'redux/store';

const StoreAddressUpdate = () => {
  const { data } = useSelector((state) => state.model);
  const { googleMapApiKey } = useSelector((state) => state.chargebee);
  const { memberships } = useSelector((state) => state.store);
  const { membershipIndex, userId } = data;
  const { users } = memberships[membershipIndex];
  const { memberNumber, email, mobilePhone, homePhone } = getActiveUser(users, userId);

  const {
    address,
    addressErrors,
    handleAddressAutoComplete,
    handleAddressChange,
    handleAddressDropDown,
    validateAddress
  } = useAddress();

  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    email,
    mobilePhone,
    homePhone
  });

  const [isEmailNotMandatory, setIsEmailMandatory] = useState(email.length === 0);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    street: '',
    suburb: '',
    postcode: '',
    email: '',
    mobilePhone: '',
    homePhone: ''
  });

  useEffect(
    () =>
      setErrors({
        street: '',
        suburb: '',
        postcode: '',
        email: '',
        mobilePhone: '',
        homePhone: ''
      }),
    [address, userData]
  );

  const handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    if (name === HOME_PHONE || name === MOBILE_PHONE) {
      value = formatPhoneNo(value);
    }
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleEmailCheckbox = (ev) => {
    setIsEmailMandatory(ev.target.checked);
    setUserData({ ...userData, email: '' });
    setErrors({ ...errors, email: '', mobilePhone: '' });
  };

  const getSelectedOption = (optionList, value) => {
    if (optionList !== undefined) {
      let optionSelected = optionList.find((option) => option.value === value);
      return optionSelected === undefined ? null : optionSelected;
    }
    return null;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (
      precheck(
        userData,
        validateAddress(address.addressLine1),
        errors,
        setErrors,
        isEmailNotMandatory
      )
    ) {
      setIsLoading(true);
      const payload = {
        ...address,
        email: userData.email,
        mobilePhone: userData.mobilePhone,
        homePhone: userData.homePhone,
        memberNumber
      };
      dispatch(updateBillingAddress(payload))
        .then(() => {
          postAction();
          dispatch(
            showSnackbar({ severity: 'success', message: 'Contact details updated successfully' })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(showSnackbar({ severity: 'error', message: err?.data?.message }));
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(closeModel());
        });
    }
  };

  const postAction = () => {
    let mList = JSON.parse(JSON.stringify(memberships));
    let membership = mList[membershipIndex];

    membership.users = membership.users.map((user) => {
      if (user.memberNumber === userId) {
        return {
          ...user,
          email: userData.email,
          mobilePhone: userData.mobilePhone,
          homePhone: userData.homePhone,
          addressDetails: address
        };
      }
      return user;
    });

    mList[membershipIndex] = membership;
    dispatch(updateMemberships(mList));

    let { mobilePhone, homePhone } = userData;
    dispatch(
      updateContactDetails({
        mobilePhone,
        homePhone,
        address
      })
    );
  };

  return (
    <>
      <ModelHeader closeButton>
        <ModelTitle>Contact Details</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <Container>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-wrap">
                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLookup">Address Lookup </LabelNR>
                  <Autocomplete
                    className="form-control"
                    apiKey={googleMapApiKey}
                    placeholder={''}
                    onPlaceSelected={(s) => handleAddressAutoComplete(s)}
                    options={{
                      types: ['geocode', 'establishment'],
                      fields: [
                        'name',
                        'geometry',
                        'place_id',
                        'formatted_address',
                        'address_components'
                      ],
                      componentRestrictions: { country: 'aus' }
                    }}
                  />
                </div>

                <div className="form-member-group-lg">
                  <Label htmlFor="addressLine1">Address Line 1 </Label>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine1"
                    value={address.addressLine1}
                    onChange={handleAddressChange}
                    maxLength="200"
                    autoComplete="off"
                  />
                  <span className="error">{addressErrors.addressLine1}</span>
                </div>

                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLine2">Address Line 2 </LabelNR>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine2"
                    value={address.addressLine2}
                    onChange={handleAddressChange}
                    maxLength="200"
                    autoComplete="off"
                  />
                </div>

                <div className="form-member-group-lg">
                  <LabelNR htmlFor="addressLine3">Address Line 3 </LabelNR>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine3"
                    value={address.addressLine3}
                    onChange={handleAddressChange}
                    maxLength="200"
                    autoComplete="off"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Label htmlFor="suburb">Suburb</Label>
              <input
                type="text"
                className="form-control"
                name="suburb"
                value={address.suburb}
                onChange={handleAddressChange}
                maxLength="100"
                autoComplete="off"
              />
              <span className="error">{addressErrors.suburb}</span>
            </Col>
            <Col md={6}>
              <Label htmlFor="postcode">Postcode</Label>
              <input
                type="text"
                className="form-control"
                name="postcode"
                value={address.postcode}
                onChange={handleAddressChange}
                maxLength="4"
                autoComplete="off"
              />
              <span className="error">{addressErrors.postcode}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Select
                className="mt-3_5"
                styles={dropDown(false)}
                options={STATES}
                isSearchable={false}
                value={getSelectedOption(STATES, address.state)}
                onChange={handleAddressDropDown}
                placeholder={'*Select State/Territory'}
              />
              <span className="error">{addressErrors.state}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <LabelNR className={isEmailNotMandatory ? '' : 'required'} htmlFor="email">
                Email Address
              </LabelNR>
              <input
                type="text"
                className="form-control"
                name="email"
                value={userData.email}
                onChange={handleChange}
                maxLength="70"
                disabled={isEmailNotMandatory}
                autoComplete="off"
              />
              <span className="error">{errors.email}</span>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isEmailNotMandatory}
                      onChange={handleEmailCheckbox}
                    />
                  }
                  label={<CheckboxContent>Customer does not have an email address</CheckboxContent>}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <LabelNR className={isEmailNotMandatory ? 'required' : ''} htmlFor="mobilePhone">
                Mobile Phone (04x xxx xxxx)
              </LabelNR>
              <input
                type="text"
                className="form-control"
                name="mobilePhone"
                value={userData.mobilePhone}
                onChange={handleChange}
                maxLength="12"
                autoComplete="off"
              />
              <span className="error">{errors.mobilePhone}</span>
            </Col>
            <Col md={6}>
              <LabelNR htmlFor="homePhone">Home Phone (0xx xxx xxxx)</LabelNR>
              <input
                type="text"
                className="form-control"
                name="homePhone"
                value={userData.homePhone}
                onChange={handleChange}
                maxLength="12"
                autoComplete="off"
              />
              <span className="error">{errors.homePhone}</span>
            </Col>
          </Row>
          <div className="text-center mb-3 mt-3">
            <Btn
              onClick={handleSubmit}
              style={isLoading ? disable : active}
              isProcessing={isLoading}
              disabled={isLoading}>
              {isLoading ? <ButtonLoader /> : 'Save'}
            </Btn>
          </div>
        </Container>
      </ModelBody>
    </>
  );
};

export default StoreAddressUpdate;
