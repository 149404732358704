import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from 'routes/wrapper/requireAuth';
import lazyWithFallback from 'utils/lazyWithFallback';

const Home = lazyWithFallback(() => import('pages/portal/home'));
const EditMembership = lazyWithFallback(() => import('pages/portal/editMembership'));
const FamilyDetails = lazyWithFallback(() => import('pages/portal/familyDetails'));
const Payment = lazyWithFallback(() => import('pages/portal/payment'));
const PayNow = lazyWithFallback(() => import('pages/portal/payNow'));
const Billing = lazyWithFallback(() => import('pages/portal/billing'));
const Communication = lazyWithFallback(() => import('pages/portal/communication'));
const EditCommunicationInfo = lazyWithFallback(() => import('pages/portal/communication/edit'));
const ThankYou = lazyWithFallback(() => import('pages/portal/thankYou'));
const Confirmation = lazyWithFallback(() => import('pages/portal/confirmation'));
export const PortalRoutes = () => (
  <Route element={<RequireAuth />}>
    {/*portal route*/}
    <Route exact path="/mymembership/home" element={<Home />} />
    <Route exact path="/mymembership/edit" element={<EditMembership />} />
    <Route exact path="/mymembership/family" element={<FamilyDetails />} />
    <Route exact path="/mymembership/payment" element={<Payment />} />
    <Route exact path="/mymembership/payNow" element={<PayNow />} />
    <Route exact path="/mymembership/billing" element={<Billing />} />
    <Route exact path="/mymembership/communication" element={<Communication />} />
    <Route exact path="/mymembership/communication/edit" element={<EditCommunicationInfo />} />
    <Route exact path="/mymembership/thankYou" element={<ThankYou />} />
    <Route exact path="/mymembership/confirmation" element={<Confirmation />} />
  </Route>
);
