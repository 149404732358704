import { createSlice } from '@reduxjs/toolkit';
import { NA_SUPPORT_MESSAGE } from 'utils/constants/variable';

const initialState = {
  visible: false,
  content: null,
  severity: null
};
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.visible = true;
      state.content = action.payload.message ?? NA_SUPPORT_MESSAGE;
      state.severity = action.payload.severity;
    },
    hideSnackbar: (state) => {
      state.visible = false;
      state.content = null;
      state.content = null;
    }
  }
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
