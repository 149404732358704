import { createSlice } from '@reduxjs/toolkit';
import { initUserFlow, loggedIn } from './auth';
import api from 'utils/functions/api';
import { NP_ENGINE } from 'settings/dev';
import { ACTION, PORTAL } from 'utils/constants/variable';

const initialState = {
  loggedInByPrimaryUser: true,
  plans: [],
  planDropdown: [],
  users: [],
  familyList: [],
  loggedInUser: '',
  customerId: '',
  subscriptionId: '',
  membershipPlan: '',
  paymentPlanPortal: '',
  tempMembershipPlan: '',
  tempPaymentPlan: '',
  itemList: [],
  isConcessionPreApplied: false,
  isConcessionApplied: false,
  totalCost: '',
  validUntil: '',
  isFutureRenewal: true,
  concession: null,
  cardType: null,
  crn: '',
  couponPortal: '',
  couponAppliedPortal: false,
  title: '',
  isCouponApplied: false,
  estimate: {},
  isEstimated: false,
  membership: {},
  selectedAction: ACTION.VIEW_MY_MEMBERSHIP,
  badAddress: false
};

export const portal = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    portalInit: (state, action) => {
      let {
        loggedInByPrimaryUser,
        plans,
        planDropdown,
        users,
        familyList,
        loggedInUser,
        customerId,
        subscriptionId,
        membershipPlan,
        paymentPlan,
        membershipPlanName,
        paymentPlanName,
        isConcessionApplied,
        validUntil,
        nextPaymentDue,
        isFutureRenewal,
        itemList,
        totalCost,
        concession,
        cardType,
        crn,
        couponPortal,
        couponAppliedPortal
      } = action.payload;

      state.loggedInByPrimaryUser = loggedInByPrimaryUser;
      state.plans = plans;
      state.planDropdown = planDropdown;
      state.users = users;
      state.familyList = familyList;
      state.loggedInUser = loggedInUser;
      state.customerId = customerId;
      state.subscriptionId = subscriptionId;
      state.membershipPlan = membershipPlan;
      // state.paymentPlanPortal = paymentPlan;
      state.tempMembershipPlan = membershipPlan;
      state.tempPaymentPlan = paymentPlan;
      state.membershipPlanName = membershipPlanName;
      state.paymentPlanName = paymentPlanName;
      state.itemList = itemList;
      state.isConcessionPreApplied = isConcessionApplied;
      state.isConcessionApplied = isConcessionApplied;
      state.totalCost = totalCost;
      state.validUntil = validUntil;
      state.nextPaymentDue = nextPaymentDue;
      state.isFutureRenewal = isFutureRenewal;
      state.concession = concession;
      state.cardType = cardType;
      state.crn = crn;
      state.couponPortal = couponPortal;
      state.couponAppliedPortal = couponAppliedPortal;
      // state.title = estimate.name;
      // state.estimate = estimate;
    },
    updatePortal: (state, action) => {
      let {
        membershipPlan,
        paymentPlan,
        isConcessionApplied,
        itemList,
        totalCost,
        concession,
        cardType,
        crn,
        familyList
      } = action.payload;

      state.membershipPlan = membershipPlan;
      state.paymentPlanPortal = paymentPlan;
      state.itemList = itemList;
      state.isConcessionApplied = isConcessionApplied;
      state.totalCost = totalCost;
      state.concession = concession;
      state.cardType = cardType;
      state.crn = crn;
      state.familyList = familyList;
    },
    updateMembership: (state, action) => {
      state.membership = action.payload;
    },
    addCoupon: (state, action) => {
      state.couponApplied = true;
      state.couponCode = action.payload;
    },
    resetCoupon: (state) => {
      state.couponApplied = false;
      state.couponCode = '';
    },
    couponUpdated: (state, action) => {
      state.couponAppliedPortal = action.payload.couponAppliedPortal;
      state.couponPortal = action.payload.couponPortal;
    },
    paymentPlanUpdated: (state, action) => {
      let { paymentPlan, subscriptionId } = action.payload;
      state.paymentPlanPortal = paymentPlan;
      state.subscriptionId = subscriptionId;
    },
    renewalCompleted: (state, action) => {
      let { users, validDate, nextPaymentDueDate } = action.payload;
      state.validUntil = validDate;
      state.nextPaymentDue = nextPaymentDueDate;
      state.users = users;
      state.isSuccess = true;
    },
    updatePlanDetails: (state, action) => {
      state.couponAppliedPortal = action.payload.isApplied;
      state.couponPortal = action.payload.coupon;
      state.paymentPlanPortal = action.payload.paymentPlan;
    },
    updatePanelAction: (state, action) => {
      state.selectedAction = action.payload;
    },
    updateBadAddress: (state, action) => {
      state.badAddress = action.payload;
    },
    disableNpCard: (state, action) => {
      const memberNumber = action.payload;
      let clone = { ...state.membership };
      clone.users = clone.users.map((user) => {
        if (user.memberNumber === memberNumber) {
          user.npCard = false;
        }
        return user;
      });
      state.membership = clone;
    },
    portalReset: () => initialState
  }
});

export const processRenewal = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/store/changeSubscription`, payload).then((response) => {
    dispatch(renewalCompleted(response));
    return response;
  });

export const searchMembershipUsingEncodedUrl = (id) => (dispatch) =>
  api.get(NP_ENGINE, `/common/quickRenewal?param=${id}`).then((response) => {
    dispatch(initUserFlow(PORTAL.CUSTOMER));
    return response;
  });

export const searchMembershipFromPortal = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/common/quickRenewal`, payload).then((response) => {
    dispatch(initUserFlow(PORTAL.CUSTOMER));
    return response;
  });

export const updateUserDetails = (payload) => () =>
  api.post(NP_ENGINE, '/customer/update', payload).then((response) => {
    return response;
  });

export const portalLoggingIn = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/auth/authenticate`, payload).then((response) => {
    dispatch(initUserFlow(PORTAL.CUSTOMER));
    dispatch(loggedIn());
    return response;
  });

export const portalPlanUpgrade = (payload) => () =>
  api.post(NP_ENGINE, `/customer/upgrade`, payload).then((response) => {
    return response;
  });

// Action creators are generated for each case reducer function
export const {
  portalInit,
  updateMembership,
  updatePortal,
  couponUpdated,
  addCoupon,
  resetCoupon,
  updatePlanDetails,
  portalReset,
  renewalCompleted,
  paymentPlanUpdated,
  updatePanelAction,
  disableNpCard,
  updateBadAddress
} = portal.actions;

export default portal.reducer;
