import { ModelHeader, ModelTitle, ModelBody, ModelContent } from './style';
import { validateURL } from 'utils/functions/security';
import { useSelector } from 'react-redux';

export default function TermsAndConditions() {
  const { url } = useSelector((state) => state.chargebee);

  return (
    <div>
      <ModelHeader closeButton>
        <ModelTitle>MEMBERSHIP TERMS AND CONDITIONS</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <ModelContent>
          The following terms & conditions apply to the use of a member card issued by Friendly
          Society Medical Association Limited ABN 69 088 347 602 of 52 Gawler Place Adelaide 5000
          trading as “National Pharmacies” (National Pharmacies), and to the use of the National
          Pharmacies smartphone app in place of a physical member card.
        </ModelContent>
        <ol>
          <li>
            These terms and conditions are to be read in conjunction with the National Pharmacies
            constitution (the terms of which bind all members) and your application for membership.
            Additional terms and conditions may also apply in connection with the use of member
            cards and the National Pharmacies smartphone app from time to time, as set out in any
            other documents provided or made available to you by National Pharmacies at the time of
            joining or at any time thereafter.
          </li>
          <li>
            These terms and conditions may be varied at any time without prior notice by National
            Pharmacies, in accordance with the National Pharmacies constitution.
          </li>
          <li>
            {' '}
            A member card is valid only whilst the member in respect of whom it was issued remains a
            financial member of National Pharmacies. Acceptance and use of the membership card or
            the National Pharmacies smartphone app constitutes acceptance of these terms and
            conditions.
          </li>
          <li>
            Member cards and smartphone app logins are not transferable and is for the personal use
            of the member named on the card only. In the case of a family membership, an additional
            member card and smartphone app logins may be requested for each family member who is a
            dependant of the member (as determined by National Pharmacies).
          </li>
          <li>
            Member benefits can only be claimed on presentation of either: an active plastic member
            card issued by National Pharmacies; or a smartphone showing the National Pharmacies app
            [showing an active membership logged in], which must be scanned at the time of purchase.
            Retrospective benefits will not be granted. National Pharmacies does not accept member
            cards that have been copied, scanned, photographed or otherwise reproduced or
            replicated.
          </li>
          <li>
            The member card remains the property of National Pharmacies and will be made inactive if
            the membership ceases, or on request. You remain responsible for your member card and
            you must report its loss immediately should it be misplaced or stolen or copied. Should
            your address, email, phone number or other details change, you must provide us your
            updated details either online or by visiting one of our stores.
          </li>
          <li>
            If you joined by adding National Pharmacies membership to your health insurance which is
            later cancelled or changed for some reason, please contact our Member Services
            department to discuss another way of continuing your National Pharmacies membership.
          </li>
          <li>
            National Pharmacies is a company limited by shares and guarantee. By becoming a member
            of National Pharmacies, you agree, in accordance with the National Pharmacies
            constitution, to provide a guarantee of $1.00 in the event the company is wound up.
          </li>
          <li>
            Members are entitled to cancel their membership at any time by request in writing. No
            refund of membership contributions paid in advance will be given.
          </li>
          <li>
            Membership contribution rates and benefits are subject to change without prior notice,
            in accordance with the National Pharmacies constitution. However, any change will only
            apply to future contributions and transactions.
          </li>
          <li>
            Failure to adhere to the terms and conditions of membership may lead to expulsion from
            National Pharmacies or other action being taken by National Pharmacies against you.
          </li>
        </ol>
      </ModelBody>

      <ModelHeader>
        <ModelTitle>NOTICE OF MEETINGS</ModelTitle>
      </ModelHeader>

      <ModelBody>
        <ol>
          <li>
            As a member of Friendly Society Medical Association Limited (“Company”) you may elect to
            receive written notice of meetings of members of the Company. Attendance at the AGM and
            other meetings of members of the company enables you as a member to:
            <ol className="roman">
              <li>participate in the governance of the Company;</li>
              <li>
                ask questions about, and comment on, the management of the Company, its financial
                standing and performance; and
              </li>
              <li>
                to ask the auditor of the Company questions about the conduct of the audit of the
                Company and the preparation and content of the auditor ‘s report; and
              </li>
              <li>
                to vote on any proposal to amend the constitution of the Company or on any other
                matter in relation to the management of the Company.
              </li>
            </ol>
          </li>
          <li>
            Notice of meetings from the Company to members informs members about matters in relation
            to which members may wish to attend a meeting.
          </li>
          <li>
            As a member of the Company who is entitled to attend and cast a vote at a meeting, you
            may appoint a proxy to attend and vote for you at the meeting.
          </li>
          <li>
            If you as a member do not elect to receive notice of these meetings, the Company is not
            required to give notice of these meetings to you. Despite a member of the Company
            electing not to receive notice of these meetings, or not making an election, a member
            may at any time request the Company to give the member personal notice of such meetings.
          </li>
          <li>
            If you wish to make an election to receive notice of meetings of the members of the
            Company, please complete and sign the election at the end of this notice.
          </li>
        </ol>
      </ModelBody>

      <ModelHeader>
        <ModelTitle>
          YOU MAY ELECT TO RECEIVE A COPY OF THE ANNUAL REPORT FOR THE COMPANY
        </ModelTitle>
      </ModelHeader>
      <ModelBody>
        <ol>
          <li>
            The Annual Report sets out information about:
            <ol className="roman">
              <li>the financial position and performance of the Company; and</li>
              <li>the efficiency with which the Company is being managed;</li>
              <li>the financial risks to which the Company is exposed.</li>
            </ol>
          </li>
          <li>
            If you do not elect to receive an Annual Report, the Company is not required to send
            copies of the Annual Report to you.
          </li>
          <li>
            Despite not electing to receive the Annual Report you may at any time elect to receive
            the Annual Report.
          </li>
          <li>
            If you do not elect to receive a copy of the Annual Report, you may access it on the
            National Pharmacies website:&nbsp;
            <a href={validateURL(url?.wordpressHome)}>nationalpharmacies.com.au</a>
          </li>
          <li>
            If you wish to receive a copy of the Annual Report, please complete and sign the
            election at the end of this notice.
          </li>
        </ol>
      </ModelBody>
    </div>
  );
}
