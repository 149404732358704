import { ModelHeader, ModelTitle, ModelBody, ModelContent } from './style';

export default function PaymentTermsAndConditions() {
  return (
    <div>
      <ModelHeader closeButton>
        <ModelTitle>Subscription/Automatic Recurring Payment Plan Agreement</ModelTitle>
      </ModelHeader>
      <ModelBody>
        <ModelContent>
          This Membership Subscription/Automatic Recurring Payment Plan Agreement (“Agreement”) is a
          contract between you and National Pharmacies to obtain a payment on a repeat or regular
          basis for your membership subscription. Please verify that all information you provide is
          complete and accurate before accepting this agreement.
        </ModelContent>
        <br />
        <div className="mt-3_5">
          No other discounts are eligible or available when taking advantage of recurring payment
          plans.
        </div>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Making Payments</ModelTitle>
        <ModelContent>
          On acceptance of this agreement, payments will be automatically collected as per your
          chosen payment plan. We offer the following types of Recurring Payment Plan Agreements:
        </ModelContent>
        <div className="mt-3_5">
          <b>Annual Payment Subscription:</b> You agree to an initial pre-payment for 1 full year of
          membership at the time of accepting this agreement. The membership will expire in 365 days
          and you will be notified prior to the anniversary date for renewal and then annually
          thereafter. You may cancel your membership at anytime subject to the terms of our
          cancellation policy.
        </div>

        <div className="mt-3_5">
          <b>12 Membership Subscription with payment instalments:</b> When you purchase a
          subscription using the instalment plan, you agree to prepay an initial cost of the
          membership and the remaining balance will be debited at regular intervals over the 12
          month membership subscription. Subsequent debits will be charged to your provided
          credit/debit card according to the chosen agreement:
        </div>
        <div className="mt-3_5">
          <u>Monthly payment plan</u> - on or around the anniversary date of each month (barring
          holidays and weekends).
        </div>

        <div className="mt-3_5">
          <u>Three monthly payment plan</u> - will occur approximately 90 days after your initial
          purchase and every 90 thereafter until you reach membership expiry
        </div>

        <div className="mt-3_5">
          <u>Six monthly payment plan</u> - will occur approximately 180 days (during the 6th month)
          after your initial purchase month.
        </div>

        <div className="mt-3_5">
          You will not receive any advance notice of impending debit to your card, but you will
          receive a post-payment receipt confirming your successful payment. The membership will
          expire in 365 days and you will be notified prior to the anniversary date for renewal and
          then annually thereafter. You may cancel your membership at any time subject to the terms
          of our cancellation policy.
        </div>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Payment Failure</ModelTitle>
        <ModelContent>
          If your automatic recurring payment is declined or unable to be processed for any reason,
          you will be contacted by email with an extended period to make your payment. We will
          automatically retry to debit your card within 3 days of the initial missed payment.
        </ModelContent>
        <div className="mt-3_5">
          However, in the event that the re-attempted payment fails, and any other payment is not
          made by the required extended due date, your membership will be cancelled.
        </div>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Changing Payments</ModelTitle>
        <ModelContent>
          If you need to maintain to your credit/debit card details including updating your expiry
          date or change from the card number that was initially selected to another credit/debit
          card, you can update your details via our online member website xxxxxxxxxxxxxxxxx or by
          calling National Pharmacies Membership Services on ph xxxxxxxxxxxxx. All changes will be
          updated immediately for all current and future payments. Adding a valid concession card
          number or seniors card details to obtain membership discounts can be updated at any time
          during your membership however the discount will only come into effect at the next renewal
          of your membership.
        </ModelContent>
        <div className="mt-3_5">
          If you would like to change your automatic recurring payment method from one plan to
          another plan, for example, making a change from an initial selected 3 monthly plan to an
          annual plan, this can be completed online and only at the anniversary of any existing 12
          month membership period. Only 1 change can be made to your payment structure in any 12
          month period.
        </div>
        <div className="mt-3_5">
          Any other request to change your plan structure will need to be directed to NP Membership
          Services and will be subject to approval.
        </div>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Auto Renewal</ModelTitle>
        <ModelContent>
          <b>Annual Payment Subscriptions:</b> Your subscription will auto renew each year on your
          anniversary date, notification of your renewal will be sent to you 15 days prior to advise
          you of your new membership period including the start date and expiry of the new
          membership period, any changes to the amount due and any changes to the terms of the
          membership.
        </ModelContent>
        <div className="mt-3_5">
          <b>12 Membership Subscription with payment instalments:</b> Your subscription will NOT
          auto renew each year and you will need to log in to our online member website to complete
          your renewal if you wish to continue with your existing instalment payments. You will
          receive a notification 15 days prior to your membership expiry date advising you of your
          new membership option including start date and expiry, new payment due dates and any
          changes to the amounts due or to the membership terms. You will have the option to change
          to any other instalment plan or to annual plan during the renewal period.
        </div>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Cancellation and Refunds</ModelTitle>
        <ModelContent>
          Members are entitled to cancel their membership at any time as per our general Terms and
          Conditions policy, via our online member website, in writing to PO Box xxxx, Adelaide SA
          5000 or by phoning Membership Services on xxxxxxx. No refund of membership contributions
          paid in advance will be given.
        </ModelContent>
      </ModelBody>

      <ModelBody>
        <ModelTitle>Agreeing to these Conditions</ModelTitle>
        <ModelContent>
          By providing your credit or debit card, you AGREE that you have read and understand this
          Agreement. In addition, you authorize NP to charge the full amount required by your chosen
          payment plan (initial payment, monthly payment, 3-month payment, and 6-month payment) to
          the specified Payment Method; and you authorize the financial institution for the Payment
          Method specified above to charge your account and remit payment for your membership to
          National Pharmacies. This authority will remain in effect until you give notification, as
          required under this Agreement, to terminate this authorization; provided, however, that no
          termination of authorization will relieve you of any obligation to pay amounts due.
        </ModelContent>
      </ModelBody>
    </div>
  );
}
