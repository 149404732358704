import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { PORTAL } from 'utils/constants/variable';
import api from 'utils/functions/api';
import { NP_ENGINE } from 'settings/dev';

const initialState = {
  isUserLoggedIn: false,
  userFlow: '',
  searchMember: false,
  token: null,
  loggedInUserId: '',
  policy: []
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.isUserLoggedIn = true;
    },
    loggedOut: (state) => {
      state.isUserLoggedIn = false;
      state.token = null;
    },
    initUserFlow: (state, action) => {
      state.userFlow = action.payload;
    },
    loadPolicy: (state, action) => {
      state.policy = action.payload;
    },
    enableSearch: (state) => {
      state.searchMember = true;
    },
    disableSearch: (state) => {
      state.searchMember = false;
    },
    setCredentials: (state, action) => {
      const { memberno } = jwt_decode(action.payload);
      state.token = action.payload;
      state.loggedInUserId = memberno;
      state.userFlow = PORTAL.CUSTOMER;
      state.isUserLoggedIn = true;
    },
    authReset: () => {
      return initialState;
    }
  }
});

export const {
  loggedIn,
  loggedOut,
  loadPolicy,
  authReset,
  enableSearch,
  disableSearch,
  setCredentials,
  initUserFlow
} = auth.actions;

export default auth.reducer;

export const selectCurrentToken = (state) => state.auth.token;

export const selectLoggedInUserId = (state) => state.auth.loggedInUserId;

export const selectIsUserLoggedIn = (state) => state.auth.isUserLoggedIn;

// Customer Portal Logout
export const logout = () => (dispatch) =>
  api.get(NP_ENGINE, '/auth/logout').then((response) => {
    dispatch(authReset());
    return response;
  });
