import { createSlice } from '@reduxjs/toolkit';
import api from 'utils/functions/api';
import { NP_ENGINE } from 'settings/dev';
import { PURCHASE_STATUS } from 'utils/constants/variable';
import { isNullOrEmpty } from 'utils/functions/validator';

const initialState = {
  index: 0,
  membershipPlan: '',
  paymentPlan: '',
  billingAddress: '',
  customerId: '',
  subscriptionId: '',
  address: {},
  primaryUser: {},
  dependants: [],
  couponApplied: false,
  isConcessionApplied: false,
  crn: '',
  cardType: '',
  couponCode: '',
  totalPrice: 0,
  discount: 0,
  amountWithoutDiscount: 0,
  credits: 0,
  isEstimated: false,
  operation: '',
  purchaseStatus: ''
};

export const estimate = createSlice({
  name: 'estimate',
  initialState,
  reducers: {
    initUserDetails: (state, action) => {
      let {
        index,
        customerId,
        subscriptionId,
        membershipPlan,
        paymentPlan,
        isConcessionApplied,
        crn,
        cardType,
        address,
        operation,
        primaryMember,
        dependants,
        purchaseStatus
      } = action.payload;

      state.index = index;
      state.customerId = customerId;
      state.subscriptionId = subscriptionId;
      state.membershipPlan = membershipPlan;
      state.paymentPlan = paymentPlan;
      state.address = address;
      state.isConcessionApplied = isConcessionApplied;
      state.crn = crn;
      state.cardType = cardType;
      state.operation = operation;
      state.primaryMember = primaryMember;
      state.dependants = dependants;
      state.purchaseStatus = purchaseStatus;
    },
    updateEstimate: (state, action) => {
      let { price, discount, amountWithoutDiscount, couponCode, credits, name } = action.payload;

      state.price = price;
      state.discount = discount;
      state.amountWithoutDiscount = amountWithoutDiscount;
      state.couponCode = couponCode;
      state.couponApplied = !isNullOrEmpty(couponCode);
      state.credits = credits;
      state.name = name;
      state.isEstimated = true;
    },
    addCoupon: (state, action) => {
      state.couponApplied = true;
      state.couponCode = action.payload;
    },
    resetCoupon: (state) => {
      state.couponApplied = false;
      state.couponCode = '';
    },
    purchaseCompleted: (state) => {
      state.purchaseStatus = PURCHASE_STATUS.COMPLETED;
    },
    estimateReset: () => {
      return initialState;
    }
  }
});

export const {
  initUserDetails,
  updateEstimate,
  addCoupon,
  resetCoupon,
  purchaseCompleted,
  estimateReset
} = estimate.actions;

export const getEstimate = (payload) => (dispatch) =>
  api.post(NP_ENGINE, `/common/estimate`, payload).then((response) => {
    dispatch(updateEstimate(response));
    return response;
  });

export default estimate.reducer;
