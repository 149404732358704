import { useState } from 'react';
import { useSelector } from 'react-redux';

const initialState = {
  addressLine1: '',
  suburb: '',
  postcode: '',
  state: ''
};

const useAddress = () => {
  const { addressObj } = useSelector((state) => state.details);
  const [address, setAddress] = useState(addressObj);
  const [addressErrors, setAddressErrors] = useState(initialState);
  const [badAddress, setBadAddress] = useState(addressObj.isBadAddress);

  const handleAddressChange = (ev) => {
    ev.preventDefault();
    let { name, value } = ev.target;
    setAddress({ ...address, [name]: value, isBadAddress: false });
    setAddressErrors({ ...addressErrors, [name]: '' });
    setBadAddress(false);
  };

  const handleAddressDropDown = ({ value }) => {
    setAddress({ ...address, state: value, isBadAddress: false });
    setAddressErrors({ ...addressErrors, state: '' });
    setBadAddress(false);
  };

  const handleAddressAutoComplete = ({ name, address_components }) => {
    let houseNo = '';
    let subpremise = '';
    let street = '';
    let suburb = '';
    let postcode = '';
    let state = '';
    let country = '';

    address_components.forEach((component) => {
      const { short_name, long_name } = component;
      const componentType = component.types[0];
      switch (componentType) {
        case 'subpremise':
          subpremise = `${long_name}`;
          break;
        case 'point_of_interest':
          subpremise = `${long_name} ${subpremise}`;
          break;
        case 'street_number':
          street = `${long_name} ${street}`;
          houseNo = long_name;
          break;
        case 'route':
          street += long_name;
          break;
        case 'postal_code':
          postcode = `${long_name}${postcode}`;
          break;
        case 'postal_code_suffix':
          postcode = `${postcode}-${long_name}`;
          break;
        case 'locality':
          suburb = long_name;
          break;
        case 'administrative_area_level_1':
          state = short_name;
          break;
        case 'country':
          country = short_name;
          break;
      }
    });

    let addressDetails = {
      addressLine3: '',
      suburb,
      postcode,
      state,
      country
    };

    if (subpremise) {
      setAddress({
        addressLine1: subpremise,
        addressLine2: street,
        isBadAddress: false,
        ...addressDetails
      });
    } else if (houseNo !== '' && name.includes(houseNo)) {
      setAddress({
        addressLine1: street,
        addressLine2: '',
        isBadAddress: false,
        ...addressDetails
      });
    } else {
      setAddress({
        addressLine1: name,
        addressLine2: street,
        isBadAddress: false,
        ...addressDetails
      });
    }
    setAddressErrors({ ...initialState });
    setBadAddress(false);
  };

  const validateAddress = (addressLineOne) => {
    const addressKeyList = ['suburb', 'state'];
    let errorClone = { ...addressErrors };
    let isValid = true;

    if (addressLineOne.trim().length === 0) {
      errorClone['addressLine1'] = 'This field is required.';
      isValid = false;
    }

    addressKeyList.forEach((val) => {
      if (address[val].trim().length === 0) {
        if (val === 'state') {
          errorClone[val] = 'Please select a valid value';
        } else {
          errorClone[val] = 'This field is required.';
        }
        isValid = false;
      }
    });

    // Postcode empty and validate
    if (address['postcode'].trim().length === 0) {
      errorClone['postcode'] = 'This field is required.';
      isValid = false;
    } else if (validatePostcode(address['postcode'], address['state'])) {
      errorClone['postcode'] = 'Please enter a valid value';
      isValid = false;
    }

    setAddressErrors(errorClone);

    return isValid;
  };
  const validatePostcode = (postcode, stateCode) => {
    const postcodePatterns = {
      NSW: /^[2-8][0-9]{3}$/,
      VIC: /^[3][0-9]{3}$/,
      QLD: /^[4-7][0-9]{3}$/,
      SA: /^[5][0-9]{3}$/,
      WA: /^[6][0-9]{3}$/,
      TAS: /^[7][0-9]{3}$/,
      ACT: /^[0-9]{4}$/,
      NT: /^[0-9]{4}$/
    };

    // Check if the state code is valid
    if (!postcodePatterns[stateCode]) {
      return false; // Invalid state code
    }

    // Use the regular expression to validate the postcode
    return !postcodePatterns[stateCode].test(postcode);
  };

  return {
    address,
    addressErrors,
    badAddress,
    handleAddressAutoComplete,
    handleAddressChange,
    handleAddressDropDown,
    validateAddress,
    setAddress
  };
};

export default useAddress;
