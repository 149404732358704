import styled from 'styled-components';

export const Heading = styled.div.attrs({
  className: 'text-center filson-pro-bold'
})`
  font-size: 20px;
  color: blue;
`;

export const Title = styled.div.attrs({
  className: 'text-center filson-pro-bold'
})`
  font-size: 1.5rem;
  margin-bottom: 4vh;
`;

export const UserWrap = styled.div.attrs({
  className: 'd-flex flex-wrap justify-content-center'
})``;

export const UserName = styled.div.attrs({
  className: 'filson-pro-bold'
})`
  margin-right: 8px;
`;

export const MemberNumber = styled.div``;
